import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Method from '@ui/API/Method';

import classes from './style.module.scss';

const Destination = ({ destination, isInTabOrder, setIsOpen }) => {
  const isLinkType = destination.type === 'link';

  const Link = !isLinkType
    ? NavLink
    : ({ to, ...props }) => (
        <a {...props} href={to}>
          {props.children}
        </a>
      );

  return (
    <li className={classes['destination-item']}>
      <Link
        className={classes['destination-item-anchor']}
        onClick={() => setIsOpen(false)}
        rel={destination.linkExternal ? 'noopener' : undefined}
        tabIndex={isInTabOrder ? 0 : -1}
        target={destination.linkExternal ? '_blank' : '_self'}
        to={isLinkType ? destination.linkUrl : `/reference/${destination.slug}`}
      >
        <div className={classes['destination-item-anchor-row-container']}>
          {destination.title}
          {destination.type === 'endpoint' && !!destination.method && (
            <Method
              className={classes['destination-item-anchor-row-container-method']}
              fixedWidth
              type={destination.method}
            />
          )}
          {!!isLinkType && (
            <i aria-hidden="true" className={`${classes['Sidebar-link-externalIcon']} icon-arrow-up-right`} />
          )}
        </div>
      </Link>
    </li>
  );
};

const destinationPropTypes = {
  /**
   * Array of destination objects to be navigated and filtered
   */
  destination: PropTypes.shape({
    /**
     * Unique ID for the destination
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Boolean for if the destination is a link outside the reference section
     */
    isLink: PropTypes.bool,
    /**
     * Boolean for if the destination is an external URL
     */
    linkExternal: PropTypes.bool,
    /**
     * String of the destination URL
     */
    linkUrl: PropTypes.string,
    /**
     * Unique ID for the parent category
     */
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /**
     * Title of the parent category
     */
    parentTitle: PropTypes.string,
    /**
     * Slug for the destination's link
     */
    slug: PropTypes.string,
    /**
     * Title for this destination link
     */
    title: PropTypes.string,
    /**
     * API endpoint type
     */
    type: PropTypes.string,
  }),
};

Destination.propTypes = {
  ...destinationPropTypes,
  /**
   * Boolean for if the destination should be in the tab order
   */
  isInTabOrder: PropTypes.bool,
  /**
   * Callback function for opening and closing the modal
   */
  setIsOpen: PropTypes.func,
};

export default Destination;
export { destinationPropTypes };
