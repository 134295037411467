import Oas from 'oas';
import { Operation, Callback } from 'oas/operation';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useDereference from '@core/hooks/useDereference';

import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const CallbackExpression = ({ callback, oas, operation }) => {
  const { isDereferenced } = useDereference(oas);

  const operationId = useMemo(() => operation.getOperationId(), [operation]);

  const schemasWithProps = useMemo(() => {
    if (!isDereferenced) return null;

    return { jsonSchemas: operation.getParametersAsJSONSchema(), statusCodes: operation.getResponseStatusCodes() };
  }, [isDereferenced, operation]);

  const TooltipContent = ({ param }) => {
    const expressionProps = [];

    const checkProps = (properties, type) => {
      if (properties) {
        Object.keys(properties).forEach(prop => {
          // check if this property is included in the param
          if (param.includes(prop)) expressionProps.push({ name: prop, type });
          // if this property has additional properties, recursively check those too
          if (properties[prop].properties) checkProps(properties[prop].properties, type);
        });
      }
    };
    // Check all schemas returned from getParametersAsJSONSchema() to check if their properties are referenced in the callback
    schemasWithProps?.jsonSchemas?.forEach(schema => {
      checkProps(schema.schema?.properties, schema.type);
    });

    // Check all response bodies for properties to check if their properties are referenced in the callback
    schemasWithProps?.statusCodes?.forEach(status => {
      const response = operation.getResponseByStatusCode(status);
      if (response?.content) {
        Object.keys(response.content).forEach(contentType => {
          if (response.content[contentType].schema?.properties)
            checkProps(response.content[contentType].schema?.properties, 'response');
        });
      }
    });

    // if the expression doesn't reference any properties, just return null
    if (expressionProps.length === 0) return null;

    const mentionedParams = expressionProps
      .map(prop => (
        <span key={`param-${prop.name}_${prop.type}`}>
          <span className={classes['Callbacks-expression-connected']} data-testid="expression-param">
            {prop.name}
          </span>{' '}
          {`${prop.type ? prop.type : ''} parameter`}
        </span>
      ))
      // add in 'and' if there's only two props, commas if theres more, or nothing if there's only one
      .reduce((prev, curr) => [prev, expressionProps.length > 2 ? ', ' : ' and ', curr]);

    return <>This data comes from the {mentionedParams} of this request.</>;
  };

  const ExpressionTooltip = ({ children, param, index }) => {
    // Only render the tooltip if there's reference props
    if (!TooltipContent({ param })) return children;

    return (
      <Tooltip
        key={`path-${operationId}_${param}-${index}`}
        content={<TooltipContent param={param} />}
        interactive={false}
        maxWidth="500px"
      >
        {children}
      </Tooltip>
    );
  };

  /**
   * Pull out any params in the callback path and find their linked properties from request/response bodies
   *
   * @see createHeaderUrl in '@ui/API/Header/index.jsx'
   */
  let pathParts = callback.path.split(/\{|\}/g);

  if (pathParts.length > 1) {
    const finalPathParts = [];

    pathParts.forEach((param, i) => {
      // When we split off the curly brace the even elements are the param names
      if (i % 2) {
        finalPathParts.push(
          <ExpressionTooltip key={`path-${operationId}_${callback.path}-${callback.method}`} index={i} param={param}>
            <label key={`path-${operationId}_${param}-${i}`} className={classes['Callbacks-expression-parameter']}>
              {`{${param}}`}
            </label>
          </ExpressionTooltip>,
        );
      } else {
        // And the odd elements are the string literals
        finalPathParts.push(param);
      }
    });

    pathParts = finalPathParts;
  }

  return <div className={classes['Callbacks-expression']}>{pathParts}</div>;
};

CallbackExpression.propTypes = {
  callback: PropTypes.instanceOf(Callback).isRequired,
  index: PropTypes.number,
  oas: PropTypes.instanceOf(Oas).isRequired,
  operation: PropTypes.instanceOf(Operation).isRequired,
  param: PropTypes.string,
};

export default CallbackExpression;
