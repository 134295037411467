import Oas from 'oas';
import { Operation, Callback } from 'oas/operation';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

import Method from '@ui/API/Method';
import ResponseSchemaPicker from '@ui/API/ResponseSchemaPicker';
import { createSchema } from '@ui/API/Schema';
import SectionHeader from '@ui/API/SectionHeader';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';
import Tooltip from '@ui/Tooltip';

import CallbackExpression from './CallbackExpressionTooltip';
import classes from './style.module.scss';
import './style.scss';

const CallbackContent = ({ callback, oas, operation }) => {
  const [expandedCallback, setExpandedCallback] = useState(false);

  const CallbacksSchema = useMemo(() => {
    return createSchema(oas, operation, false, true);
  }, [oas?.api?._id, operation]); // eslint-disable-line react-hooks/exhaustive-deps

  const summary = callback.getSummary();
  const description = callback.getDescription();

  const body = summary || description ? [summary, description].join('\n\n') : undefined;

  return (
    <section className={`${classes['Callbacks-section']} rm-Callbacks`}>
      <Flex
        align="center"
        className={`${classes['Callbacks-button']}`}
        onClick={() => setExpandedCallback(!expandedCallback)}
        tag="button"
        type="button"
      >
        <div>
          <div className={classes['Callbacks-label']}>
            <div className={classes['Callbacks-label-text']}>
              {callback.hasOperationId() ? callback.getOperationId() : callback.identifier}
            </div>
            <Method className={classes['Callbacks-label-method']} fixedWidth type={callback.method} />
            {callback.isDeprecated() && <Method className={classes['Callbacks-label-method']} type="deprecated" />}
          </div>
          <CallbackExpression callback={callback} oas={oas} operation={operation} />
        </div>
        <i
          aria-label="Expand"
          className={`${classes['Callbacks-expand']} ${expandedCallback ? 'icon-chevron-up' : 'icon-chevron-down'} ${
            expandedCallback ? '' : classes['Callbacks-expand-closed']
          }`}
        />
      </Flex>
      {!!expandedCallback && (
        <div className={`${classes['Callbacks-option']}`}>
          {!!body && <RDMD className={classes['Callbacks-option-description']}>{body}</RDMD>}
          <CallbacksSchema key={`schema${callback.path}${callback.method}`} oas={oas} operation={callback} />
          {!!callback.schema.responses && <ResponseSchemaPicker isCallback={true} oas={oas} operation={callback} />}
        </div>
      )}
    </section>
  );
};

function Callbacks({ oas, operation }) {
  if (!operation.hasCallbacks()) return null;
  const allCallbacks = operation.getCallbacks();
  return (
    <>
      <div className={classes['Callbacks-header']}>
        <SectionHeader heading={`Callback${allCallbacks.length > 1 ? 's' : ''}`} />
        <Tooltip
          content="A callback is an API request that’s sent from the API provider to the provided URL when a specified event occurs. You send this URL to the API provider by using the endpoint on this page."
          interactive={false}
        >
          <span className={`icon-help-circle ${classes['Callbacks-header-help-icon']}`} />
        </Tooltip>
      </div>
      {allCallbacks.map((callback, index) => (
        <CallbackContent
          key={`callback-${callback.path}-${callback.method}-${index}`}
          callback={callback}
          oas={oas}
          operation={operation}
        />
      ))}
    </>
  );
}

CallbackContent.propTypes = {
  callback: PropTypes.instanceOf(Callback).isRequired,
  oas: PropTypes.instanceOf(Oas).isRequired,
  operation: PropTypes.instanceOf(Operation).isRequired,
};

Callbacks.propTypes = {
  oas: PropTypes.instanceOf(Oas).isRequired,
  operation: PropTypes.instanceOf(Operation).isRequired,
};

export default Callbacks;
