import PropTypes from 'prop-types';
import React from 'react';

import {
  CustomArrayFieldTemplate,
  CustomObjectFieldTemplate,
  CustomTemplateShell,
  CustomTemplate,
  getCustomType,
  CustomNamelessTemplate,
} from './CustomTemplates';
import BaseSchemaField from './Form/components/fields/SchemaField';
import { findSchemaDefinition, hasSchemaType, isPolymorphic } from './Form/utils';

function doesFormatExist(widgets, type, format) {
  const availableFormats = Object.keys(widgets);
  const valuedType = Array.isArray(type) ? type.find(word => word !== 'null') : type;

  return typeof valuedType === 'string' && availableFormats.includes(format);
}

function SchemaField(props) {
  let { schema } = props;
  const { depth, uiSchema } = props;

  // If we have a schema like the following cases we should back out and not render anything out
  // because the schema is either falsy or malformed and completely unusable.
  //
  //    "type": "object",
  //    "properties":
  //      "first": { "type": "string" },
  //      "second": false
  //
  // Or this:
  //
  //    "type": "object",
  //    "properties":
  //      "first": { "type": "string" },
  //      "second": "not a schema object"
  if (typeof schema !== 'object' || schema === null) {
    return null;
  }

  // If this schema is going to be loaded with a $ref, prefetch it so we'll have a schema type to
  // work with.
  if ('$ref' in schema) {
    schema = Object.assign(props.schema, findSchemaDefinition(schema.$ref, props.registry.rootSchema));
    delete schema.$ref;
  }

  // If we have a format that isn't recognized by any widget that we've set up in the form, we
  // should remove it because we won't know how to render it and schemas without a format will just
  // get rendered automatically a `string` anyways.
  if (schema.format && !doesFormatExist(props.registry.widgets, schema.type, schema.format)) {
    delete schema.format;
  }

  // Sometimes polymorphic schemas have object types at a level outside of the oneof/anyof
  // In those cases we don't want to use the custom templates below
  if (!isPolymorphic(schema)) {
    // Render our new custom object and array formats
    if (schema?.type) {
      if (hasSchemaType(schema, 'object')) {
        props.uiSchema['ui:ObjectFieldTemplate'] = CustomObjectFieldTemplate;
      } else if (hasSchemaType(schema, 'array')) {
        props.uiSchema['ui:ArrayFieldTemplate'] = CustomArrayFieldTemplate;
      }
    }
  }

  // If there's no name on this field, then it's a lone schema with no label or children and as
  // such we shouldn't try to render it with the custom SchemaField template.
  if ('name' in props) {
    // This allows an element to be created and bypass the template, for example when we hide the
    // discriminator in the object ui
    if (uiSchema['ui:customTemplate'] === false) {
      // We have to delete it because there's a chance something else on the page already assigned
      // the custom template.
      delete props.uiSchema['ui:FieldTemplate'];
    } else {
      props.uiSchema['ui:FieldTemplate'] = CustomTemplate;
    }
  } else if ('oneOf' in schema || 'anyOf' in schema) {
    // If this is a oneOf or anyOf schema, render it using a shell of a CustomTemplate that will
    // render it within our `div.param` work so it doesn't look like hot garbage.
    //
    // See https://github.com/readmeio/api-explorer/pull/436#issuecomment-597428988 for more info.
    props.uiSchema['ui:FieldTemplate'] = CustomTemplateShell;
  } else {
    // We need a custom template for top level items (identified by not having a name) that don't
    // already have a custom template, so we grab it here
    props.uiSchema['ui:FieldTemplate'] = CustomNamelessTemplate;
  }

  // Hide readonly props in the request params
  if ('readOnly' in schema && schema.readOnly && !props.uiSchema['ui:responseDocs']) {
    return null;
  }

  // Hide writeonly in the response docs
  if ('writeOnly' in schema && schema.writeOnly && props.uiSchema['ui:responseDocs']) {
    return null;
  }

  const customType = getCustomType(schema);
  if (customType) {
    return (
      <BaseSchemaField
        {...props}
        depth={depth}
        schema={schema}
        uiSchema={{ ...props.uiSchema, classNames: `field-${customType}` }}
      />
    );
  }

  // Transform `const` schemas into a single-item dropdown.
  if ('const' in schema) {
    schema.enum = [schema.const];
    return (
      <BaseSchemaField
        {...props}
        depth={depth}
        schema={schema}
        uiSchema={{ ...props.uiSchema, 'ui:widget': 'select' }}
      />
    );
  }

  // Transform booleans from a checkbox into a dropdown.
  if (hasSchemaType(schema, 'boolean')) {
    schema.enumNames = ['true', 'false'];
    return (
      <BaseSchemaField
        {...props}
        depth={depth}
        schema={schema}
        uiSchema={{ ...props.uiSchema, 'ui:widget': 'select' }}
      />
    );
  }

  return <BaseSchemaField {...props} depth={depth} schema={schema} />;
}

SchemaField.propTypes = {
  depth: PropTypes.number,
  registry: PropTypes.shape({
    FieldTemplate: PropTypes.func,
    rootSchema: PropTypes.object,
    widgets: PropTypes.object,
  }).isRequired,
  schema: PropTypes.shape({
    $ref: PropTypes.string,
    const: PropTypes.string,
    enum: PropTypes.array,
    enumNames: PropTypes.array,
    format: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    writeOnly: PropTypes.bool,
  }).isRequired,
  uiSchema: PropTypes.shape({
    'ui:ArrayFieldTemplate': PropTypes.elementType,
    'ui:callbackDocs': PropTypes.bool,
    'ui:customTemplate': PropTypes.bool,
    'ui:FieldTemplate': PropTypes.elementType,
    'ui:ObjectFieldTemplate': PropTypes.elementType,
    'ui:responseDocs': PropTypes.bool,
  }),
};

SchemaField.defaultProps = {
  depth: 0,
  uiSchema: {
    'ui:customTemplate': true,
  },
};

export default function createSchemaField() {
  return SchemaField;
}
