import PropTypes from 'prop-types';
import React from 'react';

function HiddenWidget({ id, value }) {
  return <input id={id} type="hidden" value={typeof value === 'undefined' ? '' : value} />;
}

HiddenWidget.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

export default HiddenWidget;
