import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Skeleton from '@ui/Skeleton';
import Toggle from '@ui/Toggle';

import { mockFilters } from './mock';
import styles from './style.module.scss';

const SKELETON_HEIGHT = '8px';
const SKELETON_BG = { '--Skeleton-bg': 'var(--gray80)' } as React.CSSProperties;

interface Props {
  showSearch?: boolean;
}

const FiltersSkeleton = ({ showSearch = true }: Props) => {
  const bem = useClassy(styles, 'FiltersSkeleton');

  return (
    <div aria-hidden="true" className={bem('&')}>
      {!!showSearch && (
        <Skeleton className={bem('-search')} height={SKELETON_HEIGHT} style={SKELETON_BG} width="100%" />
      )}

      {mockFilters.map((filter, index) => (
        <div key={index} className={bem('-group')}>
          <header className={bem('-header')}>{filter.name}</header>
          <ol className={bem('-list')}>
            {filter.skeletons.map((skeleton, i) => (
              <Flex key={i} align="center" gap="sm" justify="start" tag="li">
                <Toggle disabled type="checkbox" />
                {!!filter.showCircle && <Skeleton kind="circle" style={SKELETON_BG} />}
                {!!(skeleton as { statusCode: number; width: string }).statusCode && (
                  <HTTPStatus iconOnly status={(skeleton as { statusCode: number; width: string }).statusCode} />
                )}
                <Skeleton height={SKELETON_HEIGHT} style={SKELETON_BG} width={skeleton.width} />
                <Skeleton
                  className={bem('-end-skeleton')}
                  height={SKELETON_HEIGHT}
                  style={SKELETON_BG}
                  width={skeleton.rowEndWidth}
                />
              </Flex>
            ))}
          </ol>
        </div>
      ))}
    </div>
  );
};

export default FiltersSkeleton;
