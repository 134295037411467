import { useState, useEffect, useRef } from 'react';

interface useIsElementVisibleProps {
  /**
   * Initial visible state value. Sometimes, you may need to initialize the
   * element to a "visible" true state.
   */
  initialState?: boolean;

  /**
   * Offsets to the bounding box, effectively shrinking or growing the
   * intersection bounds. Syntax is the same as CSS margin property values, e.g.
   * `10px 0px 10px 5px`.
   */
  rootMargin?: string;

  /**
   * Visibility of target to trigger (0–1, where 0.5 is 50%).
   */
  threshold?: number;
}

interface useIsElementVisibleReturn<Element> {
  /**
   * Assign this ref to the element whose visibility needs to be observed.
   */
  ref: React.RefObject<Element | null>;

  /**
   * In most cases, `ref` should be used to assign the element. However, in rare
   * cases, you may need to observe an element defined outside of the component.
   * In those cases, use this to explicitly assign the element whose visibility
   * needs to be observed.
   * @example
   * const { setElement, visible } = useIsElementVisible();
   *
   * useEffect(() => {
   *   setElement(document.querySelector('#some-outside-element'));
   * }, []);
   */
  setElement: React.Dispatch<React.SetStateAction<Element | null>>;

  /**
   * Whether the observed element is visible on screen or not.
   */
  visible: boolean;
}

/**
 * Observes an HTML element and determines whether that element is currently
 * visible in the viewport. You can use this hook to check whether an element is
 * hidden via `display: none` or whether it's been scrolled completely outside
 * the bounds of the current viewport.
 * @example
 * const { ref, visible } = useIsElementVisible();
 *
 * useEffect(() => {
 *   if (visible) {
 *     // do something
 *   }
 * }, [visible]);
 *
 * return (
 *   <div ref={ref}>...</div>
 * )
 */
export default function useIsElementVisible<Element extends HTMLElement>({
  initialState = false,
  threshold,
  rootMargin,
}: useIsElementVisibleProps = {}): useIsElementVisibleReturn<Element> {
  const ref = useRef<Element>(null);
  const [element, setElement] = useState<Element | null>(null);
  const [visible, setVisible] = useState(initialState);

  useEffect(() => {
    if (element && ref.current) {
      throw new Error(
        'useIsElementVisible() checks visibility against either a single ref OR a single element but both are provided. Make sure only one is used.',
      );
    }

    const elementToObserve = element ?? ref.current;
    if (!elementToObserve) return () => null;

    const observer = new IntersectionObserver(
      entries => {
        const [elementEntry] = entries;
        setVisible(elementEntry.isIntersecting);
      },
      {
        rootMargin,
        threshold,
      },
    );
    observer.observe(elementToObserve);

    return () => observer.disconnect();
  }, [element, ref, rootMargin, threshold]);

  return { ref, setElement, visible };
}
