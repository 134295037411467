import * as Sentry from '@sentry/browser';
import AJV from 'ajv/dist/2020';
import AjvDraft4 from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import PropTypes from 'prop-types';
import React from 'react';

import Dropdown from '@ui/Dropdown';

import oasDialectBase from '../metaschemas/oas31/dialect-base.json';
import oasMetaBase from '../metaschemas/oas31/meta-base.json';

import { shouldShowTooltip, TooltipContents, buildKeywordBundle } from './InputTooltip';
import classes from './style.module.scss';

// OpenAPI 3.0
const ajvDraft4 = new AjvDraft4({
  coerceTypes: true,
  allErrors: true,
  // not a big fan of this, but we need some way to ignore unknown formats.
  strict: false,
  unicodeRegExp: false,
  logger: false, // Don't log unknown `format` warnings to the browser console.
});
addFormats(ajvDraft4);

const FORMAT_OPTIONS = {
  INT8_MIN: 0 - 2 ** 7, // -128
  INT8_MAX: 2 ** 7 - 1, // 127
  INT16_MIN: 0 - 2 ** 15, // -32768
  INT16_MAX: 2 ** 15 - 1, // 32767
  INT32_MIN: 0 - 2 ** 31, // -2147483648
  INT32_MAX: 2 ** 31 - 1, // 2147483647
  INT64_MIN: 0 - 2 ** 63, // -9223372036854775808
  INT64_MAX: 2 ** 63 - 1, // 9223372036854775807

  UINT8_MIN: 0,
  UINT8_MAX: 2 ** 8 - 1, // 255
  UINT16_MIN: 0,
  UINT16_MAX: 2 ** 16 - 1, // 65535
  UINT32_MIN: 0,
  UINT32_MAX: 2 ** 32 - 1, // 4294967295
  UINT64_MIN: 0,
  UINT64_MAX: 2 ** 64 - 1, // 18446744073709551615

  FLOAT_MIN: 0 - 2 ** 128, // -3.402823669209385e+38
  FLOAT_MAX: 2 ** 128 - 1, // 3.402823669209385e+38

  DOUBLE_MIN: 0 - Number.MAX_VALUE,
  DOUBLE_MAX: Number.MAX_VALUE,
};

// OpenAPI 3.1
const ajv = new AJV({ coerceTypes: true, allErrors: true, strict: false, logger: false });
ajv.addSchema(oasMetaBase, 'https://spec.openapis.org/oas/3.1/meta/base');
ajv.addMetaSchema(oasDialectBase, 'https://spec.openapis.org/oas/3.1/dialect/base');
addFormats(ajv);

export function getAjv(schemaDialect) {
  if (schemaDialect === 'http://json-schema.org/draft-04/schema#') {
    if (ajvDraft4.getSchema(schemaDialect)) {
      return ajvDraft4;
    }
  }

  if (ajv.getSchema(schemaDialect)) {
    return ajv;
  }

  return undefined;
}

function BaseInput(props) {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  if (!props.id) {
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }

  const {
    value,
    readonly,
    required,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    setIsTouched,
    formContext,
    registry,
    ...inputProps
  } = props;

  const { rootSchema } = registry;
  // If options.inputType is set use that as the input type
  if (options.inputType) {
    inputProps.type = options.inputType;
  } else if (!inputProps.type) {
    inputProps.type = 'text';
  }

  // The number input type doesn't work well with the tooltip so we override it here
  inputProps.type = inputProps.type === 'number' ? 'text' : inputProps.type;

  // If multipleOf is defined, use this as the step value. This mainly improves
  // the experience for keyboard users (who can use the up/down KB arrows).
  if (schema.multipleOf) {
    inputProps.step = schema.multipleOf;
  }

  if (typeof schema.minimum !== 'undefined') {
    inputProps.min = schema.minimum;
  }

  if (typeof schema.maximum !== 'undefined') {
    inputProps.max = schema.maximum;
  }

  if (typeof schema.minLength !== 'undefined') {
    inputProps.minLength = schema.minLength;
  }

  if (typeof schema.maxLength !== 'undefined') {
    inputProps.maxLength = schema.maxLength;
  }

  if (typeof schema.pattern !== 'undefined') {
    inputProps.pattern = schema.pattern;
  }

  // This logic was originally in `oas` in https://github.com/readmeio/oas/pull/492
  // and taken out in https://github.com/readmeio/readme/pull/12148
  if ((schema.type === 'number' || schema.type === 'integer') && 'format' in schema) {
    const formatUpper = schema.format.toUpperCase();

    if (`${formatUpper}_MIN` in FORMAT_OPTIONS) {
      if ((!schema.minimum && schema.minimum !== 0) || schema.minimum < FORMAT_OPTIONS[`${formatUpper}_MIN`]) {
        schema.minimum = FORMAT_OPTIONS[`${formatUpper}_MIN`];
      }
    }

    if (`${formatUpper}_MAX` in FORMAT_OPTIONS) {
      if ((!schema.maximum && schema.maximum !== 0) || schema.maximum > FORMAT_OPTIONS[`${formatUpper}_MAX`]) {
        schema.maximum = FORMAT_OPTIONS[`${formatUpper}_MAX`];
      }
    }
  }

  const updateInputValue = value => {
    if (value === '') {
      // For `allowEmptyValue` to work the user has to first enter in some text and then delete it.
      if (schema.allowEmptyValue) {
        props.onChange('');
      } else {
        props.onChange(undefined);
      }
    } else {
      props.onChange(value);
    }
  };

  const _onChange = ({ target: { value } }) => {
    setIsTouched(true);
    return updateInputValue(value);
  };

  const fullSchema = {
    $id: inputProps.id + Date.now(),
    $schema: schema.$schema || rootSchema.$schema || 'http://json-schema.org/draft-04/schema#',
    ...schema,
  };

  const showTooltip = shouldShowTooltip(schema);

  const finalInputProps = {
    key: inputProps.id,
    autoFocus: autofocus,
    disabled,
    readOnly: readonly,
    required,
    value: value == null ? '' : value,
    ...inputProps,
    autoComplete: 'off',
    list: schema.examples ? `examples_${inputProps.id}` : null,
    onBlur:
      onBlur &&
      (event => {
        onBlur(inputProps.id, event.target.value);
        setIsTouched(true);
      }),
    onChange: _onChange,
    onFocus: onFocus && (event => onFocus(inputProps.id, event.target.value)),
    spellCheck: false,
  };

  if (showTooltip) {
    let compiledAJV = false;
    let valid = false;
    let shouldValidate = false;
    const ajv = getAjv(fullSchema.$schema);

    if (ajv) {
      try {
        compiledAJV = ajv.compile(fullSchema);
        valid = compiledAJV(value);
      } catch (err) {
        /**
         * AJV calls might fail because of a spec might have invalid JSON Schema in a schema -- like
         * maybe an improper use of `exclusiveMinimum`.
         *
         * @see {@link https://linear.app/readme-io/issue/RM-3904}
         */
        Sentry.captureException(err);
      }
      shouldValidate = true;
    } else {
      compiledAJV = {};
      // If we can't understand the schema, don't mark the input as invalid
      valid = true;
    }

    // Check required, since it's not part of the schema we pass to ajv
    if (required && value === undefined) {
      valid = false;
    }

    if (!required && value === undefined) {
      valid = true;
    }

    return (
      <Dropdown
        key={inputProps.id}
        align="bottom"
        aria={{ content: 'describedBy', expanded: false }}
        arrow={false}
        className={classes.InputDropdown}
        clickInToClose
        justify="end"
        trigger="focus"
      >
        <input
          {...finalInputProps}
          aria-controls={inputProps.id}
          aria-expanded={false}
          className={`${inputProps.className ? inputProps.className : 'form-control'} ${valid ? '' : 'invalid'}`}
          role="combobox"
        />
        <TooltipContents
          currentValue={value}
          inputId={inputProps.id}
          placeholder={props.placeholder}
          required={required}
          schema={schema}
          shouldValidate={shouldValidate}
          updateInputValue={updateInputValue}
          validationKeywords={buildKeywordBundle(schema, compiledAJV.errors, value === undefined)}
          valueIsUndefined={value === undefined}
        />
      </Dropdown>
    );
  }

  return <input {...finalInputProps} className={`${inputProps.className ? inputProps.className : 'form-control'}`} />;
}

BaseInput.defaultProps = {
  autofocus: false,
  disabled: false,
  readonly: false,
  required: false,
};

BaseInput.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
};

export default BaseInput;
