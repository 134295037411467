/**
 * Takes a ref that contains an array of input elements
 * and assigns the current input element to the provided index.
 *
 * This pattern is weird but it's the recommended approach for arrays of refs
 * @see {@link https://stackoverflow.com/a/56063129}
 */
export default function setInputRef(
  el: HTMLInputElement | null,
  inputIndex: number,
  inputRef?: React.RefObject<(HTMLInputElement | null)[]>,
) {
  if (inputRef && Array.isArray(inputRef.current)) {
    inputRef.current[inputIndex] = el;
  }
}
