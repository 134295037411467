import React, { useState } from 'react';

import Badge from '@ui/Badge';

import classes from '../style.module.scss';

const DeprecatedAccordionWrapper: React.FC<{ childType: string }> = props => {
  const { children, childType } = props;
  const [expandedParam, setExpandedParam] = useState(false);

  return (
    <section
      key={'deprecated-accordion-wrapper'}
      className={`${classes['Param-expand']} ${classes.DeprecatedAccordion} ${
        expandedParam ? classes['Param-expand_expanded'] : ''
      }`}
      data-testid="deprecated-accordion"
    >
      <div className={'panel panel-default panel-body'}>
        <button
          aria-controls={`deprecated-form-${childType}`}
          aria-expanded={expandedParam}
          className={`Flex ${classes['Param-expand-button']} ${
            expandedParam ? classes['Param-expand-button_expanded'] : ''
          } ${classes['DeprecatedAccordion-header']}`}
          onClick={() => setExpandedParam(!expandedParam)}
          type="button"
        >
          {expandedParam ? 'Deprecated Parameters' : 'Show Deprecated'}
          {!!expandedParam && (
            <Badge className={`${classes['DeprecatedAccordion-badge']}`} kind="light">
              hide
            </Badge>
          )}
        </button>
        <div id={`deprecated-form-${childType}`}>{!!expandedParam && children}</div>
      </div>
    </section>
  );
};

export default DeprecatedAccordionWrapper;
