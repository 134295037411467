import type Oas from 'oas';
import type { Operation } from 'oas/operation';
import type { ResponseObject } from 'oas/types';

import React, { useMemo } from 'react';

import { createSchema } from '@ui/API/Schema';

import classes from './style.module.scss';

interface Props {
  className?: string;
  oas: Oas;
  onChange?: () => void;
  onClose?: () => void;
  operation: Operation;
  status: string;
}

const ResponseSchemaItems = ({ className, oas, operation, status }: Props) => {
  const ResponseSchema = useMemo(() => {
    return createSchema(oas, operation, true);
  }, [oas, operation]);

  const response = operation.getResponseByStatusCode(status) as ResponseObject;

  return (
    <div className={`${className || ''} ${classes.SchemaItems}`}>
      {!!(response.content || response.headers) && (
        <ResponseSchema
          key={`response${operation.path}${operation.method}${status}`}
          oas={oas}
          onChange={() => {}}
          onSubmit={() => {}}
          operation={operation}
          statusCode={status}
        />
      )}
    </div>
  );
};

export default ResponseSchemaItems;
