import type { ProjectClientSide } from '@readme/backend/models/project/types';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';
import { useReferenceStore } from '@core/store';

import Servers from '@ui/API/Server';

interface Props {
  oas: Oas;
  operation: Operation;
}

function ServerContainer({ oas, operation }: Props) {
  const { project } = useContext(ProjectContext);
  const [server, updateServer] = useReferenceStore(s => [s.form.schemaEditor.data.server, s.form.updateServer]);

  return (
    <Servers
      oas={oas}
      operation={operation}
      project={project as ProjectClientSide}
      server={server}
      updateServer={updateServer}
    />
  );
}

export default ServerContainer;
