import React, { forwardRef } from 'react';

import useUniqueId from '@core/hooks/useUniqueId';

import Button from '@ui/Button';
import Modal from '@ui/Modal';
import ModalBody from '@ui/Modal/Body';
import ModalFooter from '@ui/Modal/Footer';
import Title from '@ui/Title';

import classes from './style.module.scss';

export default forwardRef<Modal>(function SharedLogErrorModal(props, ref) {
  const uid = useUniqueId('MetricsShareLog');

  return (
    <>
      <Modal ref={ref} size="sm" target={`#${uid()}`} verticalCenter={true}>
        <ModalBody className={classes['TableContainer-body']}>
          <Title className={classes['TableContainer-title']} level={4}>
            URL Expired
          </Title>
          <p className={classes['TableContainer-text']}>The URL for this request expired after 30 days.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (typeof ref !== 'function') ref?.current?.toggle(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div className="ModalWrapper" id={uid()} />
    </>
  );
});
