import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useContext, useMemo } from 'react';

import { UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useMetrics from '@core/hooks/useMetrics';
import { useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import prettyNumber from '@core/utils/prettyNumber';

import SectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import MetricsGraph from '@ui/Metrics/Graph';
import Skeleton from '@ui/Skeleton';
import Spinner from '@ui/Spinner';

import EmptyStatePrompt from '../EmptyStatePrompt';
import usePollMetrics from '../usePollMetrics';
import UserAvatar from '../UserAvatar';

import classes from './style.module.scss';

// When there's no data present we want to show a mock graph in the empty state
// that should remain the same between page loads.
const mockDataset = {
  datasets: [{ data: { 0: [1, 0.5, 1.5, 1.75, 1.25, 2.25, 2], 1: new Array(7).fill(0) } }],
  labels: new Array(7).fill(''),
};

function RequestsGraph({ ephemeralHAR }) {
  const bem = useClassy(classes, 'RequestsGraph');
  const { user } = useContext(UserContext);
  const groupId = useReferenceStore(s => s.auth.hashedGroup);
  const { isDevDashEnabled } = useMetrics();

  const path = useMemo(() => {
    const params = qs.stringify(
      {
        groupBy: ['period', 'success'],
        groupId,
        rangeLength: isDevDashEnabled ? 30 : 24,
        resolution: isDevDashEnabled ? 'day' : 'hour',
      },
      stringifyOptions,
    );

    return `requests/historical?${params}`;
  }, [groupId, isDevDashEnabled]);

  const acceptResponse = (prevResponse, nextResponse) => nextResponse.total !== prevResponse.total;
  const { data, isLoading } = usePollMetrics(path, ephemeralHAR, acceptResponse);

  const requestData = data?.dataSet?.data;
  const [successTotal, errorTotal] =
    requestData?.constructor === Object
      ? Object.values(requestData).map(d => d.reduce((sum, a) => sum + a, 0))
      : [0, 0];

  const isEmptyState = successTotal === 0 && errorTotal === 0;

  if (!user) return null;

  return (
    <section>
      <Flex align="center" gap="xs">
        {!!user && <UserAvatar />}
        <SectionHeader heading="My Requests" shift="end" />
        <SectionHeader heading={isDevDashEnabled ? 'Last 30 Days' : 'Last 24 Hours'} />
      </Flex>
      <Box className={bem('-container', isLoading && '-container_loading')} kind="card">
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <>
            <h5 className={bem('-graphHeading')}>API Requests</h5>
            <Box className={bem('-graphWrapper')} kind="rule">
              {!!data && (
                <MetricsGraph
                  aspectRatio={4}
                  className={bem('-graph')}
                  data={
                    isEmptyState
                      ? mockDataset
                      : {
                          datasets: [data.dataSet],
                          labels: data.labels,
                        }
                  }
                  hideAxis
                  isCondensed
                  isInteractive={!isEmptyState}
                  sortGroupsBySum={false}
                  theme={isEmptyState ? 'empty' : 'status-code-groups'}
                  type="line"
                />
              )}
            </Box>

            <dl className={bem('-totals', isEmptyState && '_empty')}>
              <dt>
                <HTTPStatus iconOnly status="2XX" /> Success
              </dt>
              <dd>{isEmptyState ? <Skeleton /> : prettyNumber(successTotal)}</dd>
              <dt>
                <HTTPStatus iconOnly status="4XX" /> Error
              </dt>
              <dd>{isEmptyState ? <Skeleton /> : prettyNumber(errorTotal)}</dd>
            </dl>
          </>
        )}
      </Box>
      {!isLoading && !!isEmptyState && <EmptyStatePrompt />}
    </section>
  );
}

RequestsGraph.propTypes = {
  ephemeralHAR: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default RequestsGraph;
