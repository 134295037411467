import PropTypes from 'prop-types';
import React from 'react';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuHeader } from '@ui/Menu';
import RDMD from '@ui/RDMD';

import classes from './style.module.scss';
import './style.scss';

const rdmdOpts = { copyButtons: false };

const TagTooltip = ({ tags, pageId }) => {
  const TagContent = () => (
    <Menu>
      {tags.map(tag => (
        <div key={`${pageId}-${tag.name}-tag`} className={classes['TagTooltip-list']} data-testid="tag-content">
          <MenuHeader className={`${classes['TagTooltip-header']}`}>
            <Flex align="center" gap="xs">
              <div>{tag.name}</div>
              <i aria-label="Tag Icon" className={`${classes['TagTooltip-tag-icon']} icon-versions-2`} role="img" />
            </Flex>
          </MenuHeader>
          <MenuItem description={<RDMD opts={rdmdOpts}>{tag.description}</RDMD>} focusable={false} />
          {!!tag.externalDocs && (
            <MenuItem color="blue" href={tag.externalDocs.url} target="_blank">
              <div className={classes['TagTooltip-link']}>
                <a href={tag.externalDocs.url} rel="noreferrer" target="_blank">
                  <div>{tag.externalDocs.description}</div>
                  <div className={classes['TagTooltip-link-description']}>{tag.externalDocs.url}</div>
                </a>
              </div>
            </MenuItem>
          )}
        </div>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      key={`${pageId}-tagTooltip`}
      align="bottom"
      arrow={false}
      className={`rm-TagTooltip ${classes.TagTooltip}`}
      justify="start"
      sticky
    >
      <Button
        aria-label="View tags"
        className={`${classes['TagTooltip-tag-icon']} ${classes['TagTooltip-tag-button']} icon-versions-2`}
        kind="minimum"
        size="xs"
        text
      />
      <TagContent />
    </Dropdown>
  );
};

TagTooltip.propTypes = {
  pageId: PropTypes.string,
  tags: PropTypes.array.isRequired,
};

export default TagTooltip;
