import type { Entry } from 'har-format';
import type { User } from 'oas/types';

export const ZERO_CONFIG_CREATOR = 'readme-api-explorer';

export interface Creator {
  name: string;
  version: string;
}

export interface Group {
  email: string;
  id: string;
  label: string;
}

export interface MetricsUserVariable {
  user:
    | User
    | {
        email: string;
        label?: string;
        name: string;
      };
}

export interface ReadMeApiLog {
  log: {
    _version: number;
    development: boolean;
    group: Group;
    id?: string;
    request: {
      log: {
        creator: Creator;
        entries: Partial<Entry>[];
      };
    };
    url?: string;
  };
  subdomain?: string;
}
