import type { ColumnDef, RowData } from '@tanstack/react-table';

import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import Icon from '@ui/Icon';
import ReactTable from '@ui/ReactTable';

import classes from './style.module.scss';

export interface EnumDescriptions {
  description: string;
  value: string;
}

interface Props {
  data: EnumDescriptions[];
}

const EnumDescriptionsTable = ({ data }: Props) => {
  const columnHelper = createColumnHelper<EnumDescriptions>();

  const columns = [
    columnHelper.accessor('value', {
      header: 'Value',
    }),
    columnHelper.accessor('description', {
      header: 'Description',
    }),
  ];

  return (
    <details className={classes['Param-enum-descriptions']} open>
      <summary className={classes['Param-enum-descriptions-summary']}>
        <Icon className={classes['Param-enum-descriptions-icon']} name="chevron-right" />
        Show Details
      </summary>
      <ReactTable
        className={classes['Param-enum-descriptions-table']}
        columns={columns as ColumnDef<RowData>[]}
        data={data}
        hideHeader
        hidePagination
        layout="auto"
        onPageChange={() => {}} // todo: make optional for tables with no pagination
        onSort={() => {}} // todo: make optional for tables with no sorting
        page={0}
        pages={0}
        sort={{
          column: '',
          direction: 'desc',
        }}
        transparentHeader
      />
    </details>
  );
};

export default EnumDescriptionsTable;
