import type { HarRequest } from '@readme/oas-to-snippet/types';

import unzip from 'lodash/unzip';
import qs from 'qs';
import React, { useContext, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '@core/context';
import { useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import classy from '@core/utils/classy';

import APIMethod from '@ui/API/Method';
import SectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import Skeleton from '@ui/Skeleton';
import Sparkline from '@ui/Sparkline';
import Table from '@ui/Table';

import EmptyStatePrompt from '../EmptyStatePrompt';
import usePollMetrics from '../usePollMetrics';
import UserAvatar from '../UserAvatar';

import classes from './style.module.scss';

interface Action {
  label?: string;
  nav?: string;
}

interface Filter {
  label: string;
  prefix?: React.ReactNode;
}

interface RealtimeTableProps {
  actions?: Action[];
  columnNames: string[];
  ephemeralHAR?: HarRequest | null;
  filters: Filter[];
  headings: string[];
  isDevDashEnabled: boolean;
  metricsUrl: string;
  type: 'recentRequests' | 'topEndpoints';
}

type RowElement = Record<string, React.ReactNode>;

// generally copied this logic from the API LogsTable component
const RealtimeTable = ({
  actions,
  columnNames,
  ephemeralHAR,
  filters,
  headings,
  isDevDashEnabled,
  type,
}: RealtimeTableProps) => {
  const history = useHistory();

  const { user } = useContext(UserContext) || {};
  const groupId = useReferenceStore(s => s.auth.hashedGroup);

  const [filter, setFilter] = useState(filters[0]);
  const [action, setAction] = useState<Action>(actions?.[0] || {});
  const [includeErrorParams, setIncludeErrorParams] = useState(false);
  const [tableHeading, setTableHeading] = useState(headings?.[0]);

  const params = useMemo(() => {
    return qs.stringify(
      {
        groupId,
        // We'll restrict time range based on free vs. paid (Dev Dash add-on)
        rangeLength: isDevDashEnabled ? 30 : 24,
        resolution: isDevDashEnabled ? 'day' : 'hour',
        limit: 5,
        subset: includeErrorParams ? 'error' : undefined,
      },
      stringifyOptions,
    );
  }, [groupId, includeErrorParams, isDevDashEnabled]);

  const path = useMemo(() => {
    const basePath = 'requests';
    return type !== 'topEndpoints' ? `${basePath}/list?${params}` : `${basePath}/top-endpoints?${params}`;
  }, [params, type]);

  const getCount = (prevResponse, nextResponse) => {
    const prevTotal = prevResponse.reduce((acc, endpoint) => {
      const newTotal = acc + (endpoint?.total || 0);
      return newTotal;
    }, 0);
    const nextTotal = nextResponse.reduce((acc, endpoint) => {
      const newTotal = acc + (endpoint?.total || 0);
      return newTotal;
    }, 0);

    return nextTotal > prevTotal;
  };

  const checkRecentLogId = (prevResponse, nextResponse) => {
    // We can't get to an error state here, so no reason to re-request error logs repeatedly
    if (filters?.[1]?.label === '400 & 500') return true;
    // Otherwise, compare the previous first log with the current and check if their ids match
    const greaterLength = nextResponse?.logs.length > prevResponse?.logs.length;
    const idDifference = nextResponse?.logs?.[0].id !== prevResponse?.logs?.[0].id;
    return greaterLength || idDifference;
  };

  const acceptResponse = (prevResponse, nextResponse) =>
    (type === 'topEndpoints' ? getCount(prevResponse, nextResponse) : checkRecentLogId(prevResponse, nextResponse)) ||
    false;

  const { data: logs = [], isLoading } = usePollMetrics(path, ephemeralHAR, acceptResponse);

  const bodyKeys = useMemo(() => logs?.map(({ operationId }, idx) => `${operationId}-${idx}`), [logs]);

  const [body = []] = useMemo(() => {
    if (!logs?.length) {
      const emptyRow = {
        path: <Skeleton width="180px" />,
        time: <Skeleton />,
        ...(type === 'topEndpoints' && {
          spark: <Sparkline className={classes['RealtimeTable-emptyStateSparkline']} data={{}} labels={[]} />,
        }),
      };
      return [new Array(3).fill(emptyRow)];
    }

    return unzip(
      logs?.map(log => {
        // build out the row elements into rowElements
        const rowElements: RowElement = {};
        rowElements.status = (
          <Flex align="center" gap="xs" justify="start">
            <HTTPStatus iconOnly status={log.status} />
            {log.status}
          </Flex>
        );
        const date = new Date(log.startedDateTime);
        const formattedTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`;
        rowElements.time = (
          <span className={classes['RealtimeTable-table-time']} title={formattedTime}>
            {formattedTime}
          </span>
        );
        rowElements.path = (
          <>
            <APIMethod className={classes['RealtimeTable-table-method']} fixedWidth type={log.method?.toLowerCase()} />{' '}
            {log.path}
          </>
        );

        if (user) {
          rowElements.total = <span className={classes['RealtimeTable-table-time']}>{log.total}</span>;
        }

        if (type === 'topEndpoints' && user) {
          rowElements.spark = (
            <Sparkline className={classes['RealtimeTable-table-graph']} data={log.dataSet.data} labels={log.labels} />
          );
        }

        // build a row object from the rowElements based on what columnNames we have
        const row = columnNames.reduce((total, colName) => {
          total[colName] = rowElements[colName];
          return total;
        }, {});

        return [row];
      }),
    );
  }, [logs, columnNames, type, user]);

  const onFilterClick = useCallback(
    filterType => {
      const foundFilter = filters?.find(({ label }) => filterType === label);
      if (foundFilter) setFilter(filter);

      if (filterType === 'All Requests') {
        setIncludeErrorParams(false);
        setTableHeading(headings[0]);

        if (actions?.length) setAction(actions?.[0]);
      }

      if (filterType === '400 & 500') {
        setIncludeErrorParams(true);
        setTableHeading(headings[1]);
        if (actions?.length) setAction(actions?.[1]);
      }
    },
    [filters, filter, headings, actions],
  );

  const onRowClick = useCallback(
    (e, operationId) => {
      const opId = operationId.split('-').slice(0, -1).join('-');
      if (opId && opId !== 'tr' && opId !== 'undefined') history.push(`/reference/${opId.toLowerCase()}`);
    },
    [history],
  );

  let dropdown;
  // for now, we're not allowing any filtering on the Top Endpoints table, so the "dropdown" becomes a section header
  // rather than the dropdown menu that the Recent Requests table gets
  if (filters.length === 1) {
    dropdown = <SectionHeader heading={filters[0].label} />;
  } else {
    dropdown = (
      <Dropdown clickInToClose sticky>
        <Button dropdown ghost kind="secondary" size="xs" style={{ letterSpacing: 0 }}>
          <Flex align="center" gap="xs" justify="start">
            {!!filter.prefix && filter.prefix}
            {filter.label}
          </Flex>
        </Button>
        <Menu>
          {filters.map(({ label, prefix }) => (
            <MenuItem
              key={label}
              active={filter.label === label}
              className={classes['RealtimeTable-filter']}
              onClick={() => onFilterClick(label)}
            >
              <Flex align="center" gap="xs" justify="start">
                {!!prefix && prefix}
                {label}
              </Flex>
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    );
  }

  // Display "more logs" button
  const showActionsNavButton = !!actions?.length && !isLoading && type !== 'topEndpoints' && !!logs?.length;

  return (
    <div className={classes.RealtimeTable}>
      <Flex align="center" gap="xs">
        {!!user && <UserAvatar />}
        <SectionHeader heading={tableHeading} shift="end" />
        {dropdown}
      </Flex>
      <Box className={classes['RealtimeTable-table-wrapper']} kind="card">
        <Table
          align={type === 'topEndpoints' ? ['left', 'right', 'right'] : ['left', 'left', 'right']}
          body={body}
          bodyKeys={bodyKeys}
          className={classy(
            classes['RealtimeTable-table'],
            !logs?.length && classes.RealtimeTable_empty,
            type === 'topEndpoints' && classes.RealtimeTable_topEndpoints,
            type === 'recentRequests' && classes.RealtimeTable_recentRequests,
          )}
          isLoading={isLoading}
          layout="auto"
          onRowClick={onRowClick}
        />
      </Box>
      {!!showActionsNavButton && (
        <Flex align="center" gap="xs" justify="end">
          <Button size="sm" text to={action?.nav}>
            {action?.label}
            <Icon isFont name="icon-arrow-right2" />
          </Button>
        </Flex>
      )}
      {!isLoading && !logs?.length && <EmptyStatePrompt />}
    </div>
  );
};

export default RealtimeTable;
