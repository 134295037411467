import { format, subYears } from 'date-fns';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import type { DateRange, DateRangeKey, MetricsFilters } from '@core/types/metrics';
import cap from '@core/utils/capitalize';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import Select from '@ui/Select';
import Tabs from '@ui/Tabs';

import classes from './style.module.scss';

// Max range values for each resolution
const MAX_RANGES: Record<string, number> = {
  hour: 168,
  day: 120,
  week: 52,
  month: 12,
};

enum TabType {
  LAST = 'Last',
  RANGE = 'Range',
  SINCE = 'Since',
}

interface Props {
  activeFilters: Partial<MetricsFilters>;
  onApply: (key: string, range: DateRange) => void;
  onCancel: () => void;
}

const CustomRangeTooltip = ({ activeFilters, onApply, onCancel }: Props) => {
  const bem = useClassy(classes, 'DateRangeCustomTooltip');

  // Default ranges if active filters are set
  const [state, setState] = useState({
    activeTab: 'Range',
    resolution: activeFilters.resolution || 'day',
    rangeStart: activeFilters.rangeStart || '',
    rangeEnd: activeFilters.rangeEnd || '',
    rangeLength: activeFilters.rangeLength || '',
  });

  // Keep any active filters in sync with our state
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      resolution: activeFilters.resolution || 'day',
      rangeStart: activeFilters.rangeStart || '',
      rangeEnd: activeFilters.rangeEnd || '',
      rangeLength: activeFilters.rangeLength || '',
    }));
  }, [activeFilters]);

  const maxValue = MAX_RANGES[state.resolution];

  const tabContent = useMemo(() => {
    const validResolutions: DateRangeKey[] = ['hour', 'day', 'week', 'month'];
    const { activeTab, rangeStart, rangeEnd, rangeLength, resolution } = state;

    const today = format(new Date(), 'yyyy-MM-dd');
    const oneYearAgo = format(subYears(new Date(), 1), 'yyyy-MM-dd');

    switch (activeTab) {
      case TabType.RANGE:
        return (
          <>
            <FormGroup htmlFor="from" label="From" size="sm">
              <Input
                id="from"
                max={rangeEnd || today}
                min={oneYearAgo}
                onChange={e => setState({ ...state, rangeStart: e.target.value })}
                size="sm"
                type="date"
                value={rangeStart}
              />
            </FormGroup>
            <FormGroup htmlFor="to" label="To" size="sm">
              <Input
                id="to"
                max={today}
                min={rangeStart || oneYearAgo}
                onChange={e => setState({ ...state, rangeEnd: e.target.value })}
                size="sm"
                type="date"
                value={rangeEnd}
              />
            </FormGroup>
          </>
        );
      case TabType.SINCE:
        return (
          <Input
            max={today}
            min={oneYearAgo}
            onChange={e => setState({ ...state, rangeStart: e.target.value })}
            size="sm"
            type="date"
            value={rangeStart}
          />
        );
      case TabType.LAST: {
        const options = validResolutions.map(res => ({
          label: cap(rangeLength === '1' ? res : pluralize(res)),
          value: res,
        }));

        const description =
          !!rangeLength && Number(rangeLength) >= maxValue
            ? `Maximum of ${maxValue} ${cap(pluralize(resolution, maxValue))}`
            : '';

        return (
          <FormGroup description={description} htmlFor="last" size="sm">
            <InputGroup size="sm">
              <Input
                id="last"
                max={maxValue}
                min="1"
                onChange={e => setState({ ...state, rangeLength: e.target.value })}
                placeholder="30"
                type="number"
                value={rangeLength}
              />
              <Select
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setState({ ...state, resolution: e.target.value as DateRangeKey })
                }
                options={options}
                required
                value={resolution}
              />
            </InputGroup>
          </FormGroup>
        );
      }
      default:
        return null;
    }
  }, [maxValue, state]);

  const handleTabChange = useCallback(tab => {
    setState(prevState => ({
      ...prevState,
      activeTab: tab,
    }));
  }, []);

  const handleApplyClick = useCallback(() => {
    const { activeTab, rangeStart, rangeEnd, rangeLength, resolution } = state;

    let dateRange = {};

    switch (activeTab) {
      case TabType.RANGE:
        dateRange = {
          resolution: null,
          rangeLength: null,
          rangeStart,
          rangeEnd,
        };
        break;
      case TabType.SINCE:
        dateRange = {
          resolution: null,
          rangeLength: null,
          rangeStart,
          rangeEnd: format(new Date(), 'yyyy-MM-dd'),
        };
        break;
      case TabType.LAST:
        dateRange = {
          rangeStart: null,
          rangeEnd: null,
          resolution,
          rangeLength,
        };
        break;
      default:
        break;
    }

    onApply('custom', dateRange);
  }, [onApply, state]);

  const isValidRange = useMemo(() => {
    const { activeTab, rangeStart, rangeEnd, rangeLength } = state;

    switch (activeTab) {
      case TabType.RANGE:
        return !!rangeStart && !!rangeEnd;
      case TabType.SINCE:
        return !!rangeStart;
      case TabType.LAST:
        return !!rangeLength && Number(rangeLength) > 0 && Number(rangeLength) <= maxValue;
      default:
        return false;
    }
  }, [maxValue, state]);

  return (
    <Box className={bem('&')} kind="card">
      <Tabs className={{ root: bem('&-tabs'), tabs: bem('&-tabs-list') }} onClick={handleTabChange}>
        <div data-label={TabType.RANGE}></div>
        <div data-label={TabType.SINCE}></div>
        <div data-label={TabType.LAST}></div>
      </Tabs>

      <div className={bem('&-content')}>{tabContent}</div>

      <Flex align="center" className={bem('&-buttons')} justify="center">
        <Button fullWidth ghost kind="secondary" onClick={onCancel} size="sm">
          Cancel
        </Button>
        <Button disabled={!isValidRange} fullWidth kind="primary" onClick={handleApplyClick} size="sm">
          Apply
        </Button>
      </Flex>
    </Box>
  );
};

export default CustomRangeTooltip;
