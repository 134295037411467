import type { Row } from '@tanstack/react-table';
import type Oas from 'oas';

// eslint-disable-next-line readme-internal/no-restricted-imports
import { maskCredential } from '@readme/server-shared/metrics/mask-credential';
import pluralize from 'pluralize';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { VariablesContextValue } from '@core/context';
import { VariablesContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useMetrics from '@core/hooks/useMetrics';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useRealtimeStore, useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import type { APIKeyLogResponse, APIKeyRequestRow, APIKey, ApiKeyMeta, HistoricalResponse } from '@core/types/metrics';
import { isLogOlderThan30Days } from '@core/utils/metrics';

import LogDetailPopover from '@ui/Metrics/LogDetailPopover';
import RequestsPopover from '@ui/Metrics/RequestsPopover';
import Notification, { notify } from '@ui/Notification';

import useAPIKeys from '../hooks/useAPIKeys';

import MyRequestsFilters from './components/MyRequests/Filters';
import MyRequestsHeader from './components/MyRequests/Header';
import MyRequestsTable from './components/MyRequests/Table';
import classes from './style.module.scss';

interface Props {
  oas: Oas;
}

const MyRequests = ({ oas }: Props) => {
  const bem = useClassy(classes, 'MyRequests');
  const { user } = useContext(VariablesContext) as VariablesContextValue;
  const [group, groupId, updateGroup] = useReferenceStore(s => [s.auth.group, s.auth.hashedGroup, s.auth.updateGroup]);
  const { isLoggedIn } = useUserPermissions();
  const { keysSortedByLastUsed } = useAPIKeys();

  const [selectedLogMeta, setSelectedLogMeta] = useState<ApiKeyMeta>(null);
  const [logPopoverOpen, setLogPopoverOpen] = useState(false);

  // We're taking the sorted by last used keys and mapping them to the format for APIKeyDropdown
  const groups = useMemo<APIKey[]>(() => {
    return keysSortedByLastUsed?.map(({ apiKey, name }) => ({ key: apiKey, label: name }));
  }, [keysSortedByLastUsed]);

  const { isDevDashEnabled } = useMetrics();

  const [initialize, filters, getFiltersQueryString, isReady, updateFilters, resetFilters] = useRealtimeStore(state => [
    state.initialize,
    state.filters,
    state.getFiltersQueryString,
    state.isReady,
    state.updateFilters,
    state.resetFilters,
  ]);

  // Reset filters in store state on unmount
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, [resetFilters]);

  // Initialize filters when groupId is established
  useEffect(() => {
    if (!isReady && groupId) {
      initialize({
        groupId,
        rangeLength: isDevDashEnabled ? 30 : 24,
        resolution: isDevDashEnabled ? 'day' : 'hour',
      });
    }
  }, [filters, groupId, initialize, isDevDashEnabled, isReady]);

  useEffect(() => {
    // Ensure `filters.groupId` is correct when set via ReferenceStore outside of this component
    // and state has been previously initialized.
    if (groupId && groupId !== filters.groupId) updateFilters({ groupId });
  }, [groupId, filters.groupId, updateFilters]);

  const handleKeyChange = useCallback(
    (key: string) => {
      updateGroup({ apiDefinition: oas, groupId: key, user });
      updateFilters({
        groupId: maskCredential(key),
      });
    },
    [oas, updateFilters, updateGroup, user],
  );

  const onRowClick = useCallback(
    (row: Row<APIKeyRequestRow>) => {
      if ('id' in row.original) {
        // Don't show log detail popover if log is older than 30 days
        if (isLogOlderThan30Days(row.original.createdAt)) {
          notify(<Notification>Request log details not available after 30 days</Notification>, {
            position: 'top-right',
          });
          return;
        }

        setSelectedLogMeta({ id: row.original.id, createdAt: row.original.createdAt });

        // Only toggle modal open if it's not already open
        if (!logPopoverOpen) {
          setLogPopoverOpen(true);
        }
      }
    },
    [logPopoverOpen],
  );

  const closeLog = useCallback(
    targetElement => {
      // If the click is inside the table, don't close the log because it's likely a click to expand another log
      if (!!selectedLogMeta?.id && targetElement?.closest('[class^="ReactTable-"]')) return;

      setLogPopoverOpen(false);
    },
    [selectedLogMeta, setLogPopoverOpen],
  );

  const logDetailQuery = useMemo(
    () =>
      qs.stringify(
        {
          groupId,
          createdAt: selectedLogMeta?.createdAt ? new Date(selectedLogMeta?.createdAt).toISOString() : undefined,
        },
        stringifyOptions,
      ),
    [groupId, selectedLogMeta],
  );

  const { data: logDetailData, error: logError } = useMetricsAPI<APIKeyLogResponse>(
    `requests/${selectedLogMeta?.id}?${logDetailQuery}`,
    logPopoverOpen && !!selectedLogMeta?.id && !!groupId,
  );

  const historicalQuery = useMemo(() => {
    // Omit certain keys from query params
    const baseQuery = getFiltersQueryString({
      pickKeys: ['groupId', 'rangeEnd', 'rangeLength', 'rangeStart', 'resolution'],
    });

    const aggParams = qs.stringify(
      {
        groupBy: ['success', 'period'],
      },
      stringifyOptions,
    );

    return `${aggParams}&${baseQuery}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFiltersQueryString, filters]);

  // Fetch historical data for requests popover
  const { data: historicalRequestsData, isLoading: historicalRequestLoading } = useMetricsAPI<HistoricalResponse>(
    `requests/historical?${historicalQuery}`,
    isReady,
  );

  const requestsPopoverDateRangeText = useMemo(() => {
    const { rangeStart, rangeEnd, rangeLength, resolution } = filters;

    if (!!rangeStart || !!rangeEnd) return 'Custom period';
    if (!rangeLength || !resolution) return '';

    const range = Number(rangeLength) === 1 ? resolution : pluralize(resolution, rangeLength, true);

    return isDevDashEnabled ? `Last ${range}` : `Last ${range}`;
  }, [filters, isDevDashEnabled]);

  return (
    <section className={bem('&')} id="content">
      <MyRequestsHeader
        groupId={group || ''}
        groups={groups}
        isDevDashEnabled={isDevDashEnabled}
        isLoggedIn={isLoggedIn}
        onKeyChange={handleKeyChange}
      />

      <div className={bem('&-content', '&-content_min-height')}>
        <MyRequestsFilters hideAPIRequestsGraph={true}>
          {/* Show Requests popover at top of filters */}
          {!logPopoverOpen && (
            <RequestsPopover
              data={historicalRequestsData}
              isLoading={historicalRequestLoading}
              title={requestsPopoverDateRangeText}
            />
          )}
        </MyRequestsFilters>

        {!!isReady && (
          <>
            <MyRequestsTable highlightRowOnClick={logPopoverOpen} isLoggedIn={isLoggedIn} onRowClick={onRowClick} />
            <LogDetailPopover
              hasError={!!logError}
              isHub
              log={logDetailData?.log}
              onClose={closeLog}
              open={logPopoverOpen}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default MyRequests;
