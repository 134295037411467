import PropTypes from 'prop-types';
import React from 'react';

const REQUIRED_FIELD_SYMBOL = '*';

function TitleField(props) {
  const { id, title, required } = props;
  return (
    <legend id={id}>
      {title}
      {!!required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
    </legend>
  );
}

TitleField.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
};

export default TitleField;
