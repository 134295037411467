import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import ModalBody from '@ui/Modal/Body';
import ModalFooter from '@ui/Modal/Footer';
import ModalHeader from '@ui/Modal/Header';
import Title from '@ui/Title';

import classes from './style.module.scss';

interface APIResponseHeadersModalProps {
  /**
   * A collection representing the response headers
   */
  headers: {
    name: string;
    value: number | string;
  }[];

  /**
   * A function that is called when any of the dismiss buttons are clicked
   */
  onToggle: () => void;

  requestData?: {
    name: string;
    value?: string;
  }[];

  requestHeaders?: {
    name: string;
    value?: string;
  }[];
}

const APIResponseHeadersModal: React.FC<APIResponseHeadersModalProps> = ({
  headers,
  onToggle,
  requestHeaders,
  requestData,
}) => {
  const allRequestHeaders = [
    {
      title: 'Request',
      data: requestHeaders,
    },
    {
      title: 'Data',
      data: requestData,
    },
  ];
  const bem = useClassy(classes, 'HeadersModal');

  return (
    <>
      <ModalHeader toggleClose={onToggle}>
        <Title level={4}>Headers</Title>
      </ModalHeader>
      <ModalBody className={bem('-body')}>
        <header className={bem('-subtitle')}>Response</header>
        <div className={bem('-table-wrapper')}>
          <table className={bem('-table')}>
            <tbody>
              {headers.map(({ name, value }, i) => (
                <tr key={`${name}-${i}`}>
                  <td>{name}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {allRequestHeaders.map(({ title, data }) =>
          data ? (
            <React.Fragment key={`${title}-modal-body-header`}>
              <header className={bem('-subtitle')}>{title}</header>
              <div className={bem('-table-wrapper')}>
                <table className={bem('-table')}>
                  <tbody>
                    {data.map(({ name, value }, i) => (
                      <tr key={`${name}-${i}`}>
                        <td>{name}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ) : null,
        )}
      </ModalBody>

      <ModalFooter>
        <Button bem={{ shale_text: true }} onClick={onToggle}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

export default APIResponseHeadersModal;
