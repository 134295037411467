import type { PageClientSide } from '@readme/backend/models/page/types';
import type { $TSFixMe } from '@readme/iso';
import type { HarRequest } from '@readme/oas-to-snippet/types';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import React, { useContext, useState } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';

import type { AuthInputRef, OAuthFeatureFlag } from '@ui/API/Auth/types';
import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import SectionHeader from '@ui/API/SectionHeader';
import Footer from '@ui/DocFooter';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';

import AuthContainer from '../components/AuthContainer';
import RequestContainer from '../components/RequestContainer';
import { EphemeralHARContext } from '../context/HARContext';

import YourAPIKeys from './components/YourAPIKeys';
import classes from './style.module.scss';

export interface AuthenticationProps {
  doc: PageClientSide;
  inputRef: AuthInputRef;
  oas: Oas;
  oasPublicUrl: string;
  oauth: OAuthFeatureFlag;
  onError: (message: string) => void;
  operation: Operation;
  requestsEnabled: boolean;
  sidebar: $TSFixMe[];
}

const Authentication = ({
  doc,
  inputRef,
  oas,
  oasPublicUrl,
  oauth,
  onError,
  operation,
  requestsEnabled,
  sidebar,
}: AuthenticationProps) => {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);
  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);
  const [selectedHar, setSelectedHar] = useState<HarRequest | null | undefined>(null);

  return (
    <>
      <article className="rm-Article" id="content">
        <Header className={classes['RealtimePage-Header']} doc={doc} sidebar={sidebar} />
        <YourAPIKeys oas={oas} />
        {!!doc?.body && <RDMD key={doc.slug} body={doc.body} />}
        <Footer doc={doc} project={project} version={version} />
      </article>
      <div className={`${classes.rightColumn}`}>
        <SectionHeader heading="Credentials" />
        <Flex align="stretch" gap="md" layout="col">
          <AuthContainer
            apiDefinition={oas}
            customLoginEnabled={!!project.oauth_url}
            hasLegacyUI={false}
            inputRef={inputRef}
            oauth={oauth}
            operation={operation}
            setSelectedHar={setSelectedHar}
          />
          <RequestContainer
            apiDefinition={oas}
            har={selectedHar}
            onError={onError}
            operation={operation}
            requestsEnabled={requestsEnabled}
            setResponseHAR={setEphemeralHAR}
            url={oasPublicUrl}
          />
          <Response
            apiDefinition={oas}
            har={selectedHar || ephemeralHAR || null}
            onExampleRemove={clearEphemeralHAR}
            operation={operation}
            requestsEnabled={requestsEnabled}
            setSelectedHar={setSelectedHar}
          />
        </Flex>
      </div>
    </>
  );
};

export default Authentication;
