/**
 * Construct a full HAR from a request HAR and a real HTTP response.
 *
 * @param {Object} reqHAR HAR that was used to generate a request.
 * @param {Object} res Data coming from the `parseResponse` library.
 * @returns Object
 */
export default function getHARFromResponse(reqHAR, res) {
  const responseSize = () => {
    if (typeof res.responseBody === 'object' || Array.isArray(res.responseBody)) {
      return JSON.stringify(res.responseBody).length;
    }

    return res.responseBody.length;
  };

  return {
    log: {
      entries: [
        {
          startedDateTime: res.timestamp,
          request: structuredClone(reqHAR?.log?.entries?.[0]?.request),
          response: {
            status: res.status,
            statusText: res.statusText,
            headers: res.responseHeaders,
            content: {
              size: responseSize() || -1,
              mimeType: res.type,
              text: res.responseBody,
            },
          },
        },
      ],
    },
  };
}
