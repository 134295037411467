import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useRealtimeStore } from '@core/store/Realtime';
import type { MetricsFilter } from '@core/types/metrics';

import Filters from '@ui/Metrics/Filters';
import FiltersSkeleton from '@ui/Metrics/Skeletons/Filters';

import classes from './style.module.scss';

interface Props {
  hideAPIRequestsGraph: boolean;
}

const MyRequestsFilters: React.FC<Props> = ({ children, hideAPIRequestsGraph }) => {
  const bem = useClassy(classes, 'MyRequestsFilters');

  // Get filters from Realtime global store
  const [filters, isReady, getFiltersQueryString, getHasChangedFromInitialFilters, removeFilter, updateFilters] =
    useRealtimeStore(state => [
      state.filters,
      state.isReady,
      state.getFiltersQueryString,
      state.getHasChangedFromInitialFilters,
      state.removeFilter,
      state.updateFilters,
    ]);

  // Pick only group ID and date range values for all available filters request
  const allFiltersQueryParamsString = getFiltersQueryString({
    pickKeys: ['groupId', 'rangeEnd', 'rangeLength', 'rangeStart', 'resolution'],
  });

  // Omit certain keys from query params
  const filtersQueryParamsString = getFiltersQueryString({ omitKeys: ['page', 'pageSize', 'sort', 'direction'] });

  const { data: allAvailableFiltersData, isLoading } = useMetricsAPI<MetricsFilter[]>(
    `requests/filters?${allFiltersQueryParamsString}`,
    isReady,
  );

  const { data: filtersData } = useMetricsAPI<MetricsFilter[]>(`requests/filters?${filtersQueryParamsString}`, isReady);

  const filtersHaveChanged = getHasChangedFromInitialFilters();

  // Show skeleton filters if user is not logged in or no data
  if (!isReady || !filtersData?.[0]?.data?.length) {
    return <FiltersSkeleton showSearch={false} />;
  }

  return (
    <div className={bem('&')}>
      <Filters
        activeFilters={filters}
        allFiltersData={allAvailableFiltersData as MetricsFilter[]}
        clearFilter={removeFilter}
        data={filtersData as MetricsFilter[]}
        hasChangedFromInitialFilters={filtersHaveChanged}
        hideAPIRequestsGraph={hideAPIRequestsGraph}
        hideSearch
        isLoading={isLoading}
        setFilters={newFilters => {
          updateFilters({
            ...newFilters.filters,
          });
        }}
      >
        {children}
      </Filters>
    </div>
  );
};

export default MyRequestsFilters;
