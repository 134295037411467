import type { $TSFixMe } from '@readme/iso';
import type { JSONSchema } from 'oas/types';

import React from 'react';

import Textarea from '@ui/Textarea';

interface TextareaWidgetProps {
  autofocus?: boolean;
  disabled?: boolean;
  id: string;
  onBlur?: (id: TextareaWidgetProps['id'], event: $TSFixMe) => void;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: (id: TextareaWidgetProps['id'], event: $TSFixMe) => void;
  options?: {
    rows?: number;
  };
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  schema: JSONSchema;
  value?: string;
}

function TextareaWidget({
  autofocus = false,
  disabled,
  id,
  onBlur,
  onChange,
  onFocus,
  options = {},
  placeholder,
  readonly,
  required,
  schema,
  value,
}: TextareaWidgetProps) {
  const _onChange: React.ChangeEventHandler<HTMLTextAreaElement> = ({ target: { value: changedValue } }) => {
    if (changedValue === '') {
      // For `allowEmptyValue` to work the user has to first enter in some text and then delete it.
      if ('allowEmptyValue' in schema && schema.allowEmptyValue) {
        // @ts-expect-error no idea how this works but it evidently does!
        onChange('');
      } else {
        // @ts-expect-error no idea how this works but it evidently does!
        onChange(undefined);
      }
    } else {
      // @ts-expect-error no idea how this works but it evidently does!
      onChange(changedValue);
    }
  };

  return (
    <Textarea
      autoFocus={autofocus}
      disabled={disabled}
      id={id}
      onBlur={onBlur ? event => onBlur(id, event.target.value) : undefined}
      onChange={_onChange}
      onFocus={onFocus ? event => onFocus(id, event.target.value) : undefined}
      placeholder={placeholder}
      readOnly={readonly}
      required={required}
      rows={options.rows}
      spellCheck="false"
      value={typeof value === 'undefined' ? '' : value}
    />
  );
}

export default TextareaWidget;
