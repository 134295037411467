import React, { useState } from 'react';

import * as types from '../../types';
import { getWidget, getUiOptions, isSelect, optionsList, getDefaultRegistry, hasWidget } from '../../utils';

import classes from './style.module.scss';

function StringField(props) {
  const {
    schema,
    name,
    uiSchema,
    idSchema,
    formData,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    registry = getDefaultRegistry(),
    parentSchema,
  } = props;
  const { title, format } = schema;
  const { widgets, formContext } = registry;
  let enumOptions = isSelect(schema) && optionsList(schema);
  let defaultWidget = enumOptions ? 'select' : 'text';
  if (!enumOptions && format && hasWidget(schema, format, widgets)) {
    defaultWidget = format;
  }
  const { widget = defaultWidget, ...options } = getUiOptions(uiSchema);
  // Allow the parent to override the options to provide custom keys via uiSchema:{ui:options: options}
  if (options.enumOptions) {
    enumOptions = options.enumOptions;
  }

  const Widget = getWidget(schema, widget, widgets);

  // If we have examples present, let's pull the first out as a placeholder if it's a primitive value.
  let placeholder = '';
  const stringExample = parentSchema?.examples || schema.examples;
  if (typeof stringExample !== 'undefined') {
    if (Array.isArray(stringExample)) {
      const example = stringExample[0];
      if (typeof example === 'string' || typeof example === 'number' || typeof example === 'boolean') {
        placeholder = example;
      }
    }
  }

  const [isTouched, setIsTouched] = useState(false);

  return (
    <Widget
      autofocus={autofocus}
      className={`Input Input_sm ${isTouched ? 'Input_touched' : ''} ${classes['Param-input']}  rm-ParamInput`}
      disabled={disabled}
      formContext={formContext}
      id={!!idSchema && idSchema.$id}
      label={title === undefined ? name : title}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={{ ...options, enumOptions }}
      placeholder={placeholder}
      readonly={readonly}
      registry={registry}
      required={required}
      schema={schema}
      setIsTouched={setIsTouched}
      value={formData}
    />
  );
}

StringField.propTypes = types.fieldProps;

StringField.defaultProps = {
  autofocus: false,
  disabled: false,
  readonly: false,
  uiSchema: {},
};

export default StringField;
