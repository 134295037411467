import PropTypes from 'prop-types';
import React from 'react';

function RadioWidget(props) {
  const { options, value, required, disabled, readonly, autofocus, onBlur, onFocus, onChange, id } = props;
  // Generating a unique field name to identify this set of radio buttons
  const name = Math.random().toString();
  const { enumOptions, inline } = options;
  // checked={checked} has been moved above name={name}, As mentioned in #349;
  // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
  return (
    <div className="field-radio-group" id={id}>
      {enumOptions.map((option, i) => {
        const checked = option.value === value;
        const disabledCls = disabled || readonly ? 'disabled' : '';
        const radio = (
          <span>
            <input
              autoFocus={!!autofocus && i === 0}
              checked={checked}
              disabled={disabled || readonly}
              name={name}
              onBlur={!!onBlur && (event => onBlur(id, event.target.value))}
              onChange={() => onChange(option.value)}
              onFocus={!!onFocus && (event => onFocus(id, event.target.value))}
              required={required}
              type="radio"
              value={option.value}
            />
            <span>{option.label}</span>
          </span>
        );

        return inline ? (
          <label key={i} className={`radio-inline ${disabledCls}`}>
            {radio}
          </label>
        ) : (
          <div key={i} className={`radio ${disabledCls}`}>
            <label>{radio}</label>
          </div>
        );
      })}
    </div>
  );
}

RadioWidget.defaultProps = {
  autofocus: false,
};

RadioWidget.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
  }).isRequired,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  schema: PropTypes.object.isRequired,
  value: PropTypes.any,
};

export default RadioWidget;
