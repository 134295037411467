import type { InfoBadgeParentProps, InfoBadgeProps, InfoTooltipProps } from '../../types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useReferenceStore } from '@core/store';

import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import InfoPop from '@ui/InfoPop';
import RDMD from '@ui/RDMD';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const rdmdOpts = { copyButtons: false };

const tooltipContentMap = {
  base64: 'Your username and password will be combined with a : to form a base64-encoded string: `ENCODED_TOKEN`',
  basic:
    'Your username and password are being sent in the [header](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization) of the request.',
  bearer: `
  Bearer authentication gives access to the “bearer of the token” and must be sent in the Authorization header. For example:
  \`\`\`bash
  curl --request POST \\
       --url https://httpbin.org/anything/bearer
       --header 'Authorization: Bearer BEARER_TOKEN'
  \`\`\`
  `,
  cookie: 'Your API Key is being sent as a [cookie](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies).',
  header: 'Your API Key is sent in the request [header](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers).',
  jwt: "The bearer token's format is JSON Web Token (JWT). Read more at [JWT.io](https://jwt.io/).",
  query:
    'Your API Key is being sent as a query parameter in the [URL](https://developer.mozilla.org/en-US/docs/Web/API/URL).',
} as const;

/**
 * Tooltip content for OAuth flows.
 */
const OAuthFlowTooltip = ({ security }: { security: InfoBadgeProps['security'] }) => {
  const bem = useClassy(classes, 'Info');
  const [selectedFlow] = useReferenceStore(state => [state.auth.oauth.schemes[security._key]?.draftState.selectedFlow]);
  const [authorizationUrl, tokenUrl] = useReferenceStore(state => {
    return [
      selectedFlow === 'authorizationCode'
        ? state.auth.oauth.schemes[security._key]?.flows.authorizationCode?.authorizationUrl
        : '',
      selectedFlow ? state.auth.oauth.schemes[security._key]?.flows[selectedFlow]?.tokenUrl || '' : '',
    ];
  });

  return (
    <Flex className={bem('-oauth-flow-tooltip-container')}>
      {selectedFlow === 'authorizationCode' && (
        <div className={bem('-oauth-flow-tooltip-label-container')}>
          <div className={bem('-oauth-flow-tooltip-label-text')}>
            <div>Authorization URL:</div>
            <div>{`${authorizationUrl}`}</div>
          </div>
          <CopyToClipboard
            aria-label="Copy to clipboard"
            className={bem('-oauth-flow-copy-button')}
            shift="end"
            text={authorizationUrl || ''}
          />
        </div>
      )}
      <div className={bem('-oauth-flow-tooltip-label-container')}>
        <div className={bem('-oauth-flow-tooltip-label-text')}>
          <div>Token URL:</div>
          <div>{`${tokenUrl}`}</div>
        </div>
        <CopyToClipboard
          aria-label="Copy to clipboard"
          className={bem('-oauth-flow-copy-button')}
          shift="end"
          text={tokenUrl}
        />
      </div>
    </Flex>
  );
};

const InfoBadge = ({ auth, children, hideTooltip, oAuthFlowsEnabled, security }: InfoBadgeProps) => {
  const scheme = children.toLowerCase();

  let tooltipContent = tooltipContentMap[scheme];

  // If we have a scheme that can support displaying dynamic data in the tooltip, let's dig through the users auth data
  // to see if we can use it.
  if (scheme === 'base64') {
    const authParts: string[] = [];
    if (auth && security._key in auth && typeof auth[security._key] === 'object') {
      const currentAuth = auth[security._key];
      const user =
        !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.user === 'string'
          ? currentAuth.user
          : '';
      const pass =
        !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.pass === 'string'
          ? currentAuth.pass
          : '';
      if (user || pass) {
        authParts.push(user);
        authParts.push(pass);
      }
    }

    if (authParts.length === 0) {
      authParts.push('hello', 'world');
    }

    tooltipContent = tooltipContent.replace('ENCODED_TOKEN', btoa(authParts.join(':')));
  } else if (scheme === 'bearer') {
    const currentAuth = auth?.[security._key];
    if (auth && security._key in auth && typeof currentAuth === 'string' && currentAuth.length > 0) {
      tooltipContent = tooltipContent.replace('BEARER_TOKEN', currentAuth);
    } else {
      tooltipContent = tooltipContent.replace('BEARER_TOKEN', '123');
    }
  }

  const renderWithTooltip = (!!tooltipContent && !hideTooltip) || oAuthFlowsEnabled;
  const badge = (opts: { tabIndex?: number } = {}) => (
    <div
      className={`${classes['Info-infoBadge']} ${renderWithTooltip ? classes['Info-infoBadge_tooltip'] : ''}`}
      role={renderWithTooltip ? 'button' : ''}
      {...opts}
    >
      <div>{children}</div>
      {!!renderWithTooltip && (
        <i aria-label="Information" className={`${classes['Info-infoBadge-icon']} icon-info1`} role="img" />
      )}
    </div>
  );

  return renderWithTooltip ? (
    <Tooltip
      className={classes['Info-tooltip']}
      content={
        oAuthFlowsEnabled ? <OAuthFlowTooltip security={security} /> : <RDMD opts={rdmdOpts}>{tooltipContent}</RDMD>
      }
      interactive
      touch={true}
    >
      {badge({ tabIndex: 0 })}
    </Tooltip>
  ) : (
    badge()
  );
};

const InfoBadgeParent = React.memo(function InfoBadgeParent({
  auth,
  className,
  hideTooltip,
  oAuthFlowsEnabled,
  security,
}: InfoBadgeParentProps) {
  if (!security) {
    return null;
  }

  return (
    <div className={className}>
      <InfoBadge
        auth={auth}
        hideTooltip={hideTooltip}
        oAuthFlowsEnabled={oAuthFlowsEnabled}
        security={security.security}
      >
        {security.type}
      </InfoBadge>
      {security.type === 'Basic' && (
        <InfoBadge auth={auth} hideTooltip={hideTooltip} security={security.security}>
          base64
        </InfoBadge>
      )}
      {security.type === 'Bearer' && 'bearerFormat' in security.security && !!security.security.bearerFormat && (
        <InfoBadge auth={auth} hideTooltip={hideTooltip} security={security.security}>
          {security.security.bearerFormat}
        </InfoBadge>
      )}
    </div>
  );
});

const InfoTooltip = React.memo(function InfoTooltip({
  auth,
  oAuthFlowsEnabled,
  security,
  showBadges,
}: InfoTooltipProps) {
  const { description } = security.security;
  const badgeContent = showBadges && (
    <InfoBadgeParent
      auth={auth}
      className={description ? 'InfoBadge-parent-description' : ''}
      hideTooltip
      oAuthFlowsEnabled={oAuthFlowsEnabled}
      security={security}
    />
  );

  const descriptionContent = description && <RDMD opts={rdmdOpts}>{description}</RDMD>;

  return description || showBadges ? (
    <InfoPop>
      <>
        {badgeContent}
        {descriptionContent}
      </>
    </InfoPop>
  ) : null;
});

export { InfoBadgeParent, InfoTooltip };
