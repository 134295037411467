// Note: This was not originally part of rjsf, it was written for readme
import React from 'react';

import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';

import { hasSchemaType, isPolymorphic } from '../../utils';

import classes from './style.module.scss';
import './style.scss';

// We should only show the tooltip if at least one validation keywords exists on the schema
export function shouldShowTooltip(schema) {
  // If this schema is polymorphic, and somehow also has a input field, don't try to
  // render the tooltip. it's probably a mistake. Also it errors.
  if (isPolymorphic(schema)) {
    return false;
  }

  return Object.keys(schema).some(validationProp);
}

function validationProp(key, propValue) {
  if (key === 'format') {
    return [
      // Formats we can support validation from via `ajv-formats`
      'date',
      'time',
      'date-time',
      'duration',
      'uri',
      'uri-reference',
      'uri-template',
      'url',
      'email',
      'hostname',
      'ipv4',
      'ipv6',
      'regex',
      'uuid',
      'json-pointer',
      'relative-json-pointer',
      'byte',
      'int32',
      'int64',
      'float',
      'double',
      'password',
      'binary',
    ].includes(propValue);
  }

  return [
    // Basic
    'type',
    'required',
    'allowEmptyValue',
    // Number
    'multipleOf',
    'maximum',
    'exclusiveMaximum',
    'minimum',
    'exclusiveMinimum',
    // String
    'maxLength',
    'minLength',
    'pattern',
    // String & Number
    'format',
  ].includes(key);
}

export function buildKeywordBundle(schema, errors, valueIsUndefined) {
  const bundle = {};

  // Track all the validation keywords used in this schema
  Object.keys(schema).forEach(prop => {
    if (validationProp(prop, schema[prop])) {
      bundle[prop] = [];
    }
  });

  // Mark any erroring validation keywords as such by tracking their error messages
  errors?.forEach(error => {
    const { keyword, message } = error;
    // Undefined won't match any type, so we ignore it here. We handle undefined in the required logic in TooltipContents.
    // Unfortunately, if a user enters non numeric data in a number type input field, the value is always undefined.
    if (keyword === 'type' && valueIsUndefined) {
      return;
    }

    if (schema.format) {
      // Formats we support but `ajv-formats` doesn't. If we don't add this, the errors are ignored and
      // the InputTooltip will show the wrong icon.
      const additionalSupportedFormats = { int8: true, uint8: true, uint16: true, uint32: true, uint6ß4: true };
      if (additionalSupportedFormats[schema.format]) {
        bundle[keyword].push(message);
      }
      // If this isn't a `format` that we support, but there's AJV errors for it, we shouldn't
      // surface it because we don't _really_ know what it is and we'll be only showing that format
      // in the tooltip when it's invalid.
      if (!validationProp('format', schema.format)) {
        return;
      }
    }

    if (!bundle[keyword]) {
      bundle[keyword] = [];
    }

    bundle[keyword].push(message);
  });

  // This reformats pre json scham 6 to look like 6 and greater in regards to exlusive minimum/maximum
  // because it's much better looking.
  // This specific restructure ensures that invalid minimum/maximum errors are still shown.
  if (bundle.minimum && bundle.exclusiveMinimum) {
    bundle.exclusiveMinimum = bundle.minimum;
    delete bundle.minimum;
  }

  if (bundle.maximum && bundle.exclusiveMaximum) {
    bundle.exclusiveMaximum = bundle.maximum;
    delete bundle.maximum;
  }

  return bundle;
}

function buildIcon(type, shouldValidate) {
  if (!shouldValidate) {
    return null;
  }

  switch (type) {
    case 'invalid':
      return (
        <span
          aria-label="invalid"
          className={`icon icon-x-circle ${classes['InputTooltip-validation-icon']}`}
          role="img"
        />
      );
    case 'valid':
      return (
        <span
          aria-label="valid"
          className={`icon icon-check-circle ${classes['InputTooltip-validation-icon']}`}
          role="img"
        />
      );
    case 'pending':
      return (
        <span
          aria-label="pending"
          className={`icon icon-help-circle ${classes['InputTooltip-validation-icon']}`}
          role="img"
        />
      );
    default:
      return null;
  }
}

/**
 * Format the text of the keys a little better
 */
function cleanValidationKey(key) {
  if (key === 'allowEmptyValue') {
    return 'allows empty values';
  } else if (key === 'exclusiveMinimum') {
    return 'exclusive minimum';
  } else if (key === 'exclusiveMaximum') {
    return 'exclusive maximum';
  } else if (key === 'multipleOf') {
    return 'multiple of';
  }

  return key;
}

function cleanValidationValue(key, value, schema) {
  if ('format' in schema && (key === 'minimum' || key === 'maximum')) {
    if (value || typeof value === 'number') return value;

    switch (schema.format) {
      case 'int32':
        return key === 'minimum' ? '-2^31' : '2^31 - 1';
      case 'int64':
        return key === 'minimum' ? '-2^63' : '2^63 - 1';
      case 'uint32':
        if (key === 'maximum') {
          return '2^32 - 1';
        }
        break;
      case 'uint64':
        if (key === 'maximum') {
          return '2^64 - 1';
        }
        break;
      case 'float':
        return key === 'minimum' ? '-2^128' : '2^128 - 1';
      case 'double':
        // 2^308 is an approximate value for the `Number.MAX_VALUE` value that we have here.
        return key === 'minimum' ? '-2^308' : '2^308 - 1';
    }
  }

  return value;
}

export function TooltipContents(props) {
  const {
    currentValue,
    inputId,
    placeholder,
    schema,
    updateInputValue,
    valueIsUndefined,
    required,
    validationKeywords,
    shouldValidate,
  } = props;
  const contents = [];

  // We handle required outside of ajv because required is part of the object property, not the individual field
  if (required) {
    if (valueIsUndefined) {
      contents.push(
        <MenuItem key={`${inputId}-required`} className={classes['InputTooltip-validation-key']} focusable={false}>
          {buildIcon('invalid', shouldValidate)}
          required
        </MenuItem>,
      );
    } else {
      contents.push(
        <MenuItem key={`${inputId}-required`} className={classes['InputTooltip-validation-key']} focusable={false}>
          {buildIcon('valid', shouldValidate)}
          required
        </MenuItem>,
      );
    }
  }

  // If the required validation fails, don't bother showing the rest of the validation keywords
  Object.keys(validationKeywords).forEach(key => {
    let icon = null;

    // We use the ? icon for all non-required keywords if the value is undefined, OR if the value is not valid for the type.
    if (valueIsUndefined || (validationKeywords.type.length > 0 && key !== 'type')) {
      // We show a - icon next to all validation keywords but required when the value is undefined.
      icon = buildIcon('pending', shouldValidate);
    } else if (validationKeywords[key].length > 0) {
      // We show an x icon next to all invalid keywords when the value is defined
      icon = buildIcon('invalid', shouldValidate);
    } else {
      // We show a checkmark icon next to all valid keywords
      icon = buildIcon('valid', shouldValidate);
    }

    let value = schema[key];

    if (key === 'allowEmptyValue') {
      value = 'true';
    }

    // Mixed types should be rendered as a conjoined string like "string or number" or "string,
    // number, or integer".
    if (key === 'type' && Array.isArray(schema.type)) {
      value = new Intl.ListFormat('en', { type: 'disjunction' }).format(schema.type);
    }

    /**
     * The following checks help transform pre v6 json schemas to look a little more like v6 and greater
     * because v6 and greater has a cleaner ui
     */
    if (key === 'exclusiveMinimum' && schema.minimum) {
      value = schema.minimum;
    }

    if (key === 'minumum' && schema.exclusiveMinimum) {
      return;
    }

    if (key === 'exclusiveMaximum' && schema.maximum) {
      value = schema.maximum;
    }

    if (key === 'maximum' && schema.exclusiveMaximum) {
      return;
    }

    // Add the validation details to the tooltip
    contents.push(
      <MenuItem key={`${inputId}-${key}`} focusable={false}>
        {icon}
        <span className={classes['InputTooltip-validation-key']}>{cleanValidationKey(key)}:</span>
        <span className={classes['InputTooltip-validation-value']}>{cleanValidationValue(key, value, schema)}</span>
      </MenuItem>,
    );
  });

  const actions = [];

  let combinedExamples = [];
  if (schema.examples) {
    combinedExamples = [...schema.examples];
  }
  if (placeholder && !combinedExamples.includes(placeholder)) {
    combinedExamples.push(placeholder);
  }

  if (combinedExamples) {
    combinedExamples.forEach((example, index) => {
      actions.push(
        <MenuItem key={`${inputId}-example-${index}`} onClick={() => updateInputValue(example)} role="button">
          <Flex gap="2px" layout="col">
            <div>Use example value</div>
            <div className={classes['InputTooltip-description']}>{example}</div>
          </Flex>
        </MenuItem>,
      );
    });
  }

  if (schema.default) {
    actions.push(
      <MenuItem key={`${inputId}-default`} onClick={() => updateInputValue(schema.default)} role="button">
        Use default value
      </MenuItem>,
    );
  }

  if (hasSchemaType(schema, 'null')) {
    if (currentValue === null) {
      actions.push(
        <MenuItem key={`${inputId}-default`} onClick={() => updateInputValue(undefined)} role="button">
          Clear null value
        </MenuItem>,
      );
    } else {
      actions.push(
        <MenuItem key={`${inputId}-default`} onClick={() => updateInputValue(null)} role="button">
          Set null value
        </MenuItem>,
      );
    }
  }

  if (actions.length) {
    contents.push(
      <React.Fragment key={`${inputId}-divider`}>
        <MenuDivider />
        {actions}
      </React.Fragment>,
    );
  }

  return <Menu className={`rm-InputTooltip ${classes.InputTooltip}`}>{contents}</Menu>;
}
