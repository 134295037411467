import type { $TSFixMe } from '@readme/iso';

import { useCallback, useEffect, useState } from 'react';

import useMetricsAPI, { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import usePrevious from '@core/hooks/usePrevious';
import useRetry from '@core/hooks/useRetry';

type Header = Record<string, string>;

function usePollMetrics<T>(
  path: string,
  ephemeralHAR: $TSFixMe,
  acceptResponse: (prev: T | undefined, next: T) => boolean,
) {
  const prevEphemeralHAR = usePrevious(ephemeralHAR);
  const [response, setResponse] = useState<T | undefined>();
  const [headers, setHeaders] = useState<Header | undefined>();

  // Initial State Fetch
  const { data, headers: responseHeaders, isLoading } = useMetricsAPI(path);

  useEffect(() => {
    if (data) {
      setResponse(data as T);
      setHeaders(responseHeaders);
    }
  }, [data, responseHeaders]);

  const metricsFetch = useMetricsAPIFetcher<T>();
  const pollMetrics = useCallback(() => metricsFetch({ path }), [metricsFetch, path]);

  const handlePollMetrics = ({ response: nextResponse, headers: nextHeaders }: { headers: Header; response: T }) => {
    const isAccepted = acceptResponse(response, nextResponse);
    if (isAccepted) {
      setResponse(nextResponse);
      setHeaders(nextHeaders);
    }
    return isAccepted;
  };

  // Re-fetch data when we get a response from our parent component
  const isReady = prevEphemeralHAR != null && ephemeralHAR && ephemeralHAR !== prevEphemeralHAR;
  const options = {
    factor: 2,
    retries: 4,
    minTimeout: 500,
    randomize: false,
  };

  useRetry({ isReady, action: pollMetrics, callback: handlePollMetrics, options });

  return { data: response, headers, isLoading };
}

export default usePollMetrics;
