import type { $TSFixMe } from '@readme/iso';

import React, { useContext, useMemo } from 'react';

import { UserContext } from '@core/context';

import Avatar from '@ui/Avatar';

import './style.scss';

const UserAvatar = () => {
  const user = useContext(UserContext) as $TSFixMe;

  // shape of user context differs between hub and dash
  const name = user?.user?.name || user?.name;

  const initials = useMemo(
    () =>
      name
        ?.split(' ')
        .map((word: string) => word[0])
        .join(''),
    [name],
  );

  if (!name) return null;

  return (
    <Avatar className="rm-UserAvatar" hexColor="var(--UserAvatar-background)" size="xs">
      {initials}
    </Avatar>
  );
};

export default UserAvatar;
