// @todo remove this once https://linear.app/readme-io/issue/RM-8290/upgrade-module-and-moduleresolution
// is resolved. Ideally, we're processing information correctly in oas-to-har and this code becomes unnecessary

export const ensureQueryParamUniqueness = har => {
  // Only modify HARs that are recreated from API Request logs
  if (!har?.id) return har;

  // Ensure we're not modifying any values by reference
  const deepHar = structuredClone(har);
  const request = deepHar?.log?.entries?.[0]?.request;

  const { url, queryString } = request ?? {};
  if (!url || !queryString?.length) return har;

  // Strip out query params and default to `request.queryString`
  const [uri] = url.split('?') ?? [];
  if (!uri) return har;
  // Overwrite existing url with stripped value
  request.url = uri;
  return deepHar;
};
