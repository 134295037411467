import { useContext } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';

/**
 * Helper hook to pull out metrics related data from the project context.
 */
export default function useMetrics() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  return {
    /**
     * Denotes whether the project has metrics enabled
     * Free tier - restrict history to last 24 hours
     * Paid tier - restrict history to last 30 days
     */
    isDevDashEnabled: project?.metrics?.monthlyLimit > 0,
  };
}
