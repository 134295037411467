import type { $TSFixMe } from '@readme/iso';

import loadable from '@loadable/component';
import qs from 'qs';
import React, { useMemo, useCallback } from 'react';

import { useDarkMode } from '@Hub/Header/utils/hooks';

import useMediaQuery from '@core/hooks/useMediaQuery';
import { useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import type { HistoricalResponse } from '@core/types/metrics';

import usePollMetrics from '@routes/Reference/Realtime/components/usePollMetrics';

import classes from './style.module.scss';

const APILogsGraph = loadable(() => import('@ui/API/LogsGraph'), { ssr: false });

interface GraphContainerProps {
  ephemeralHAR: $TSFixMe | boolean;
  isLoggedIn: boolean;
  method: string;
  myRequestsEnabled: boolean;
  url: string;
}

function GraphContainer({ ephemeralHAR, isLoggedIn, method, url, myRequestsEnabled }: GraphContainerProps) {
  const groupId = useReferenceStore(s => s.auth.hashedGroup);

  const userScheme = useDarkMode();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const params = useMemo(() => {
    if (!groupId || !method) return '';
    // Note: we want 4 buckets for the graph, so we use a rangeLength of 4 weeks
    return qs.stringify(
      {
        resolution: 'week',
        rangeLength: 4,
        groupId,
        method,
        url,
        groupBy: ['period', 'success'],
      },
      stringifyOptions,
    );
  }, [method, groupId, url]);

  const acceptResponse = useCallback((prevResponse, nextResponse) => nextResponse?.total > prevResponse?.total, []);
  const { data, isLoading } = usePollMetrics<HistoricalResponse>(
    `requests/historical?${params}`,
    ephemeralHAR,
    acceptResponse,
  );

  return (
    <APILogsGraph
      className={classes.GraphContainer}
      data={data}
      isLoading={isLoading}
      isLoggedIn={isLoggedIn}
      myRequestsEnabled={myRequestsEnabled}
      useDarkMode={userScheme === 'dark' || (userScheme === 'system' && prefersDarkMode)}
    />
  );
}

export default GraphContainer;
