import type { BaseAPIAuthProps, SingleSecurity } from './types';

import React, { useEffect } from 'react';

import classy from '@core/utils/classy';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';

import { InfoTooltip } from './components/Info';
import AuthInput from './components/Input';
import classes from './style.module.scss';
import { useSecurityGroups } from './useSecurityGroups';

interface APIAuthProps extends BaseAPIAuthProps {
  /** The url path for the "Get API Key" button */
  apiKeyPath: string;

  /**
   * The name of the project, used for a descriptive label when the project has more than one authentication method.
   */
  projectName: string;
}

const APIAuth = ({
  apiKey,
  apiKeyPath,
  auth,
  customLoginEnabled = false,
  group,
  groups,
  isGroupLoggedIn,
  inputRef,
  oauth = false,
  onAuthGroupChange,
  operation,
  pathname = '/',
  projectName,
  selectedAuth,
  updateAuth,
  updateSecurityGroup,
}: APIAuthProps) => {
  const { securityGroups, securityGroupIndex, currentSecurityGroup, setSecurityGroupIndex } = useSecurityGroups(
    operation,
    selectedAuth,
  );

  const hasAuth = Boolean(currentSecurityGroup && currentSecurityGroup.length);

  const hasMultipleSecurityGroups = securityGroups.length > 1;

  useEffect(() => {
    if (updateSecurityGroup) updateSecurityGroup(currentSecurityGroup as SingleSecurity[], hasAuth);
  }, [currentSecurityGroup, hasAuth, updateSecurityGroup]);

  useEffect(() => {
    if (currentSecurityGroup && inputRef?.current) {
      inputRef.current = (inputRef?.current || []).slice(0, currentSecurityGroup.length);
    }
  }, [currentSecurityGroup, inputRef]);

  if (!hasAuth || !currentSecurityGroup || securityGroupIndex === -1 || currentSecurityGroup.length === 0) return null;

  const containsOauth =
    oauth && currentSecurityGroup.filter(secGroup => secGroup && secGroup.type === 'OAuth2').length > 0;
  const isLoggedIn = (groups && groups.length) || isGroupLoggedIn;

  const securityTitle = `${currentSecurityGroup[0] && currentSecurityGroup[0].type}${
    currentSecurityGroup.length > 1 ? ` +${currentSecurityGroup.length - 1}` : ''
  }`;

  return (
    <Box className={[classes.APIAuth, 'rm-APIAuth'].join(' ')} data-testid="api-auth" kind="card">
      {!!hasMultipleSecurityGroups && (
        <div className={classes['APIAuth-col']}>
          {projectName} accepts {securityGroups.length} credential methods. You can use{' '}
          {securityGroups.length === 2 ? 'either' : 'any'} of them.
          <hr className={classes['APIAuth-hr']} />
          {securityGroups.map((secGroup, i) => {
            return (
              <button
                key={i}
                className={classy(classes['APIAuth-tab'], securityGroupIndex === i && classes['APIAuth-tab_active'])}
                onClick={() => {
                  setSecurityGroupIndex(i);
                }}
              >
                <div>
                  {`${secGroup[0].type}`}
                  {secGroup.length > 1 ? (
                    <span className={classes['APIAuth-tab-subtitle']}>{` +${secGroup.length - 1} more…`}</span>
                  ) : null}
                </div>
                {securityGroupIndex === i && <i className="icon-arrow-right2" />}
              </button>
            );
          })}
        </div>
      )}
      <div className={classes['APIAuth-col']}>
        {!!apiKeyPath && !groups && (
          <>
            <section>
              <Button className={classes['APIAuth-button']} fullWidth href={apiKeyPath} size="sm">
                Get API Key <i className="icon-arrow-up-right" />
              </Button>
            </section>
            <hr className={classes['APIAuth-hr']} />
          </>
        )}

        <section className={classes['APIAuth-form']}>
          <header className={classes['APIAuth-input-heading']}>
            <div className={classes['APIAuth-badge']}>
              {securityTitle}
              {securityGroups.length === 1 && currentSecurityGroup.length === 1 && (
                <InfoTooltip auth={auth} security={currentSecurityGroup[0]} />
              )}
            </div>
            {!!containsOauth && (
              <a
                className={`${classes['APIAuth-oauthButton']} ${classes['APIAuth-button']} ${
                  isLoggedIn ? classes['APIAuth-oauthButton_logOut'] : classes['APIAuth-oauthButton_authorize']
                } rm-APIAuth-oauthButton`}
                href={`/${isLoggedIn ? 'logout' : 'oauth'}?redirect_uri=${pathname}`}
                rel="noreferrer"
                target="_blank"
              >
                {isLoggedIn ? 'Log Out' : 'Authenticate'}
              </a>
            )}
          </header>
          {currentSecurityGroup.map((security, i) => (
            <AuthInput
              key={security.security._key}
              apiKey={apiKey}
              auth={auth}
              displayTooltip={securityGroups.length > 1 || currentSecurityGroup.length > 1}
              group={group}
              groups={groups}
              inputIndex={i}
              inputRef={inputRef}
              oauth={oauth}
              onAuthGroupChange={onAuthGroupChange}
              onChange={updateAuth}
              security={security}
            />
          ))}
        </section>
        {!isLoggedIn && !!customLoginEnabled && (
          <Flex align="center" className={`${classes['APIAuth-login']} rm-APIAuth-login`} gap="sm" justify="start">
            <span aria-hidden="true" className={`${classes['APIAuth-login-icon']} icon-key1`} />
            <a
              className={classes['APIAuth-login-link']}
              data-testid="api-auth-login-link"
              href={`/login?redirect_uri=${pathname}`}
              rel="noreferrer"
            >
              Log in to use your API keys
            </a>
          </Flex>
        )}
      </div>
    </Box>
  );
};

export { default as APILegacyAuth } from './LegacyAuth';
export default React.memo(APIAuth);
