import type { AuthForHAR } from '@readme/oas-to-har/lib/types';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';
import type { SecuritySchemeObject } from 'oas/types';

/**
 * Determine if a user has qualified auth credentials to make a request against a given operation.
 */
export default function isAuthReady(oas: Oas, operation: Operation, authData: AuthForHAR = {}): boolean {
  const securitySettings = operation.getSecurity();
  if (securitySettings.length === 0) return true;

  return securitySettings.some(requirement => {
    /**
     * Sometimes, users may define multiple required security schemes that all configure the value of the same
     * request header, but OpenAPI doesn't define a way to serialize multiple values into the same request header.
     *
     * This `reduce` function below takes the required security schemes and create a map of each request header
     * to determine whether auth data is present for each request header.
     *
     * See an example of what this `reducedHeaders` object looks like below:
     * @example { 'x-api-key': false, authorization: true }
     */
    const reducedHeaders = Object.keys(requirement).reduce<Record<string, boolean>>((prev, key) => {
      // If the operations' security requirements are not properly configured as a `securitySchemes`
      // component then we should ignore it because we don't have the necessary information on how
      // to handle it, and the user won't ever be able to enter in any data for it.
      if (!oas?.api?.components?.securitySchemes?.[key]) return { '': true };

      // the API definition is dereferenced so we can assume it's a proper security scheme object
      const scheme = oas.api.components.securitySchemes[key] as SecuritySchemeObject;
      const auth = authData[key];

      // empty string is a catch-all for any auth data that isn't sent via a request header
      let requestHeader = '';
      let isFulfilled = false;

      switch (scheme.type) {
        case 'http':
          requestHeader = 'authorization';
          if (scheme.scheme === 'basic') {
            isFulfilled = Boolean(auth && typeof auth === 'object' && (auth.user || auth.pass));
          } else if (scheme.scheme === 'bearer') {
            isFulfilled = Boolean(auth);
          }

          break;

        case 'apiKey':
          if (scheme.in === 'header') {
            requestHeader = scheme.name.toLowerCase();
          }
          isFulfilled = Boolean(auth);
          break;
        case 'oauth2':
          requestHeader = 'authorization';
          isFulfilled = Boolean(auth);
          break;
        default:
          isFulfilled = Boolean(auth);
      }

      // if the request header was added by a previous security scheme and the auth data was present,
      // we respect that and don't bother adding the result of the current security scheme
      if (!prev?.[requestHeader]) {
        prev[requestHeader] = isFulfilled;
      }

      return prev;
    }, {});

    // Once the `reducedHeaders` map is constructred, we return true
    // if every value is `true`, otherwise we return false.
    return Object.keys(reducedHeaders).every(header => reducedHeaders[header]);
  });
}
