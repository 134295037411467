import React from 'react';

import SectionHeader from '@ui/API/SectionHeader';

import classes from './style.module.scss';

interface PlaygroundSectionProps {
  children: React.ReactNode;
  className?: string;
  heading?: string;
  sticky?: boolean;
}

export const PlaygroundSection = ({ className, heading, sticky, children }: PlaygroundSectionProps) => (
  <section
    className={`${classes['Playground-section']} ${sticky ? classes['Playground-section_sticky'] : ''} ${
      className || ''
    }`}
    data-notranslate
  >
    {!!heading && <SectionHeader heading={heading} />}
    {children}
  </section>
);

interface PlaygroundProps {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const Playground = ({ children = [], className, ...rest }: PlaygroundProps) => (
  <div {...rest} className={`rm-Playground ${classes.Playground} ${className || ''}`}>
    {children}
  </div>
);

export default Playground;
