import type { SidebarCategory, SidebarPage } from '@readme/iso';

import { getChildrenPages } from '@readme/iso';

interface TransformedCategory {
  children: TransformedPage[];
  id: string;
  title: string;
}

type TransformedPage = ReturnType<typeof transformPage>;

function transformPage(page: SidebarPage, endpointSection: SidebarCategory) {
  const apiMethod =
    page.type !== 'basic'
      ? 'api' in page
        ? page.api.method?.toLowerCase()
        : page.api_method?.toLowerCase()
      : undefined;

  return {
    id: '_id' in page ? page._id : page.uri,
    isLink: page.type === 'link',
    linkExternal: page.link_external || false,
    linkUrl: page.link_url || null,
    method: apiMethod || null,
    parentId: endpointSection._id || endpointSection.uri,
    parentTitle: endpointSection.title,
    slug: page.slug,
    title: page.title,
    type: page.type,
  };
}

function traversePageTree(page: SidebarPage, endpointSection: SidebarCategory, transformedPages: TransformedPage[]) {
  let queue = [page];
  while (queue.length) {
    const currentPage = queue.pop();
    if (currentPage) {
      const childPages = getChildrenPages(currentPage);
      queue = queue.concat(childPages);

      const transformedPage = transformPage(currentPage, endpointSection);
      if (!transformedPage.isLink) {
        // currently filters out links, but links could be included if desired
        transformedPages.push(transformedPage);
      }
    }
  }
}

function transformSidebarDataForQuickNav(categories: SidebarCategory[] = []) {
  const flattenedCategories: TransformedCategory[] = [];
  // iterate over each section in the endpoint sections
  categories.forEach(category => {
    const flattenedPages: TransformedPage[] = [];
    // iterate over each top-level (root level) pages in each section
    category.pages.forEach(page => {
      traversePageTree(page, category, flattenedPages);
    });

    // add section pages to the section array
    if (flattenedPages.length) {
      flattenedCategories.push({
        id: category._id || category.title,
        children: flattenedPages,
        title: category.title,
      });
    }
  });

  return flattenedCategories;
}

export default transformSidebarDataForQuickNav;
