import PropTypes from 'prop-types';
import React from 'react';

import classes from './style.module.scss';

const APIErrorBoundary = ({ errorCode, errorMessage, icon, iconLabel, padding, ...props }) => (
  <section
    className={`${classes.APIErrorBoundary} ${padding ? classes[`APIErrorBoundary_${padding}`] : ''}`}
    {...props}
  >
    <span aria-label={iconLabel} className={classes['APIErrorBoundary-icon']} role="img">
      {icon}
    </span>
    <p className={classes['APIErrorBoundary-message']}>{errorMessage}</p>
    {!!errorCode && <code className={classes['APIErrorBoundary-code']}>{errorCode}</code>}
  </section>
);

APIErrorBoundary.propTypes = {
  errorCode: PropTypes.string,
  errorMessage: PropTypes.node.isRequired,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  padding: PropTypes.oneOf(['sm', 'md', 'lg']),
};

APIErrorBoundary.defaultProps = {
  icon: '⚠️',
  iconLabel: 'Exclaimation mark in yellow triangle',
};

export default APIErrorBoundary;
