import React, { useCallback, useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import type { HistoricalResponse } from '@core/types/metrics';
import abbreviatedNumber from '@core/utils/abbreviatedNumber';
import prettyNumber from '@core/utils/prettyNumber';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import MetricsGraph from '@ui/Metrics/Graph';
import Title from '@ui/Title';

import styles from './style.module.scss';

interface Props {
  data?: HistoricalResponse;
  isLoading?: boolean;
  title: string;
}

const RequestsPopover = ({ data, isLoading, title }: Props) => {
  const bem = useClassy(styles, 'RequestsPopover');

  const [minimized, setMinimized] = useState(true);

  const handleClickOutside = useCallback(() => {
    // When open, treat any clicks as closure
    if (!minimized) {
      setMinimized(true);
    }
  }, [minimized]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const requestData = data?.dataSet?.data;
  const [successTotal, errorTotal] =
    requestData?.constructor === Object
      ? Object.values(requestData).map(d => d.reduce((sum: number, a: number) => sum + a, 0))
      : [0, 0];

  const isEmptyState = successTotal === 0 && errorTotal === 0;
  const showMinimizedTotals = !!minimized && !!data;
  const showGraph = !minimized && !!data;

  return (
    <div className={bem('&')}>
      <div
        aria-label={minimized ? 'Expand Requests detail' : 'Minimize Requests detail'}
        className={bem('-content', !minimized && !isLoading && '-content_expanded')}
        onClick={() => setMinimized(!minimized)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            setMinimized(!minimized);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <Flex align="center" className={bem('-header', !minimized && !isLoading && '-header_expanded')} gap="xs">
          <Title className={bem('-header-title')} level={5}>
            {title}
          </Title>

          {!!showMinimizedTotals && (
            <Flex align="center" className={bem('-header-totals')} gap={2}>
              <HTTPStatus iconOnly status="2XX" />
              {abbreviatedNumber(successTotal)}
              <HTTPStatus iconOnly status="4XX" />
              {abbreviatedNumber(errorTotal)}
            </Flex>
          )}

          <Icon className={bem('-header-icon')} name={minimized ? 'bar-chart-2' : 'minimize-2'} size="md" />
        </Flex>

        {!!showGraph && (
          <>
            <Box className={bem('-graph-box')} kind="rule">
              {!isEmptyState ? (
                <MetricsGraph
                  appearanceOptions={{
                    borderWidth: 3,
                  }}
                  className={bem('-graph')}
                  data={{
                    datasets: [data.dataSet],
                    labels: data.labels,
                  }}
                  hideAxis
                  isCondensed
                  isInteractive={!isEmptyState}
                  sortGroupsBySum={false}
                  theme={isEmptyState ? 'empty' : 'status-code-groups'}
                  tooltipClassName={bem('-graph-tooltip')}
                  type="line"
                />
              ) : (
                <Flex align="center" className={bem('-graph-box-empty')} justify="center">
                  No Requests
                </Flex>
              )}
            </Box>

            <dl className={bem('-totals', isEmptyState && '_empty')}>
              <dt>
                <HTTPStatus iconOnly status="2XX" /> Success
              </dt>
              <dd>{isEmptyState ? '0' : prettyNumber(successTotal)}</dd>
              <dt>
                <HTTPStatus iconOnly status="4XX" /> Error
              </dt>
              <dd>{isEmptyState ? '0' : prettyNumber(errorTotal)}</dd>
            </dl>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestsPopover;
