// Modified version of https://github.com/mozilla-services/react-jsonschema-form/blob/aadbbe9701c4db9df761e86f0280e1ecafc509f8/src/components/fields/DescriptionField.js
import PropTypes from 'prop-types';
import React from 'react';

import RDMD from '@ui/RDMD';

const rdmdOpts = { copyButtons: false };

function DescriptionField(props) {
  const { id, description } = props;
  if (!description) return null;

  return (
    <div className="field-description markdown-body" id={id}>
      {typeof description === 'string' ? <RDMD opts={rdmdOpts}>{description}</RDMD> : description}
    </div>
  );
}

export default DescriptionField;

DescriptionField.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
};

DescriptionField.defaultProps = {
  description: '',
  id: '',
};
