export const mockFilters = [
  {
    name: 'Endpoint',
    skeletons: [
      { width: '120px', rowEndWidth: '49px' },
      { width: '82px', rowEndWidth: '49px' },
      { width: '76px', rowEndWidth: '48px' },
      { width: '50px', rowEndWidth: '43px' },
      { width: '83px', rowEndWidth: '41px' },
    ],
  },
  {
    name: 'Method',
    skeletons: [
      { width: '50px', rowEndWidth: '50px' },
      { width: '50px', rowEndWidth: '50px' },
      { width: '50px', rowEndWidth: '50px' },
      { width: '50px', rowEndWidth: '50px' },
    ],
  },
  {
    name: 'Status',
    skeletons: [
      { width: '100px', statusCode: 200, rowEndWidth: '50px' },
      { width: '100px', statusCode: 200, rowEndWidth: '50px' },
      { width: '100px', statusCode: 300, rowEndWidth: '50px' },
      { width: '100px', statusCode: 400, rowEndWidth: '50px' },
      { width: '100px', statusCode: 400, rowEndWidth: '50px' },
    ],
  },
  {
    name: 'User Agent',
    skeletons: [
      { width: '52px', rowEndWidth: '34px' },
      { width: '36px', rowEndWidth: '34px' },
    ],
    showCircle: true,
  },
];
