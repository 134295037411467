import { useCallback, useRef } from 'react';

/**
 * For endpoints in the API reference that require authentication,
 * this hook supports the HTML `<input>` elements that the user
 * enters their authentication credentials into.
 */
export default function useAuthInputs() {
  const inputRef = useRef<(HTMLInputElement | null)[]>([]);

  const onAuthError = useCallback((message: string = '') => {
    // For the first input in the array that contains an invalid value
    // (e.g., an input marked as required but its value is empty),
    // we surface that error in the client.
    const containsInvalidInput = inputRef.current.some(authInput => {
      if (authInput) {
        const isValid = authInput.checkValidity();
        if (!isValid) {
          authInput.setCustomValidity(message);
          authInput.reportValidity();
          return true;
        }
      }
      return false;
    });

    // If the `message` argument is non-empty and none of the auth inputs
    // have standard HTML validation errors, this means it's a custom error
    // (e.g., a 401 response, sent via the `RequestContainer` component).
    // In this case, we surface the custom error on the first input.
    if (inputRef?.current?.[0] && message && !containsInvalidInput) {
      const firstInput = inputRef.current[0];
      if (
        firstInput.dataset?.oauthScheme &&
        message === "Sorry, you couldn't be authenticated with those credentials."
      ) {
        // for OAuth flows, we provide a more specific error message
        firstInput.setCustomValidity(
          "Sorry, you couldn't be authenticated. Please verify your OAuth 2.0 credentials and scopes.",
        );
      } else {
        firstInput.setCustomValidity(message);
      }
      firstInput.reportValidity();
    }
  }, []);

  return {
    /**
     * An array of refs, where each item is a ref to an auth input
     * for the current operation and security scheme.
     *
     * @see {@link https://stackoverflow.com/a/56063129}
     */
    inputRef,

    /**
     * Handler that surfaces validation errors when there are
     * authentication related issues
     * (e.g., missing required auth credentials, invalid auth)
     */
    onAuthError,
  };
}
