import PropTypes from 'prop-types';
import React, { Component } from 'react';

import classes from '@ui/API/Schema/components/style.module.scss';

import * as types from '../../types';
import { retrieveSchema, getDefaultRegistry, getUiOptions, ADDITIONAL_PROPERTY_FLAG } from '../../utils';
import AddButton from '../AddButton';

function DefaultObjectFieldTemplate(props) {
  const canExpand = function canExpand() {
    const { formData, schema, uiSchema } = props;
    if (!schema.additionalProperties) {
      return false;
    }
    const { expandable } = getUiOptions(uiSchema);
    if (expandable === false) {
      return expandable;
    }
    // if ui:options.expandable was not explicitly set to false, we can add
    // another property if we have not exceeded maxProperties yet
    if (schema.maxProperties !== undefined) {
      return Object.keys(formData).length < schema.maxProperties;
    }
    return true;
  };

  return (
    <div className={classes.Fieldset} id={props.idSchema.$id}>
      {props.properties.map(prop => prop.content)}
      {canExpand() && (
        <AddButton
          className="object-property-expand"
          disabled={props.disabled || props.readonly}
          onClick={props.onAddClick(props.schema)}
        />
      )}
    </div>
  );
}

class ObjectField extends Component {
  static defaultProps = {
    disabled: false,
    formData: {},
    idSchema: {},
    readonly: false,
    required: false,
    uiSchema: {},
  };

  state = {
    wasPropertyKeyModified: false,
    additionalProperties: {},
  };

  constructor(props) {
    super(props);

    if (props.discriminator) {
      this.onPropertyChange(props.discriminator.propertyName)(props.discriminator.value);
    }
  }

  isRequired(name) {
    const schema = this.props.schema;
    return Array.isArray(schema.required) && schema.required.indexOf(name) !== -1;
  }

  onPropertyChange = (name, addedByAdditionalProperties = false) => {
    return (value, fileInfo) => {
      if (value === undefined && addedByAdditionalProperties) {
        // Don't set value = undefined for fields added by
        // additionalProperties. Doing so removes them from the
        // formData, which causes them to completely disappear
        // (including the input field for the property name). Unlike
        // fields which are "mandated" by the schema, these fields can
        // be set to undefined by clicking a "delete field" button, so
        // set empty values to the empty string.
        // eslint-disable-next-line no-param-reassign
        value = '';
      }

      const newFormData = { ...this.props.formData, [name]: value };
      const newFormDataFiles = { ...this.props.formDataFiles, ...fileInfo };

      this.props.onChange(newFormData, newFormDataFiles);
    };
  };

  onDropPropertyClick = key => {
    return event => {
      event.preventDefault();
      const { onChange, formData } = this.props;
      const copiedFormData = { ...formData };
      delete copiedFormData[key];
      onChange(copiedFormData);
    };
  };

  getAvailableKey = (preferredKey, formData) => {
    let index = 0;
    let newKey = preferredKey;
    while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
      newKey = `${preferredKey}-${++index}`;
    }
    return newKey;
  };

  onKeyChange = oldValue => {
    return value => {
      if (oldValue === value) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      value = this.getAvailableKey(value, this.props.formData);
      const newFormData = { ...this.props.formData };
      const newKeys = { [oldValue]: value };
      const keyValues = Object.keys(newFormData).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: newFormData[key] };
      });
      const renamedObj = Object.assign({}, ...keyValues);

      // If the `value` has been cleared out then we should remove it from the formdata.
      if (!value) {
        delete renamedObj[oldValue];
      }

      this.setState({ wasPropertyKeyModified: true });

      this.props.onChange(renamedObj);
    };
  };

  getDefaultValue(type) {
    switch (type) {
      case 'string':
        return 'New Value';
      case 'array':
        return [];
      case 'boolean':
        return false;
      case 'null':
        return null;
      case 'number':
        return 0;
      case 'object':
        return {};
      default:
        // We don't have a datatype for some reason (perhaps additionalProperties was true)
        return 'New Value';
    }
  }

  handleAddClick = schema => () => {
    let type = schema.additionalProperties.type;
    const newFormData = { ...this.props.formData };

    if (Object.prototype.hasOwnProperty.call(schema.additionalProperties, '$ref')) {
      const { registry = getDefaultRegistry() } = this.props;
      const refSchema = retrieveSchema(
        { $ref: schema.additionalProperties.$ref },
        registry.rootSchema,
        this.props.formData,
      );

      type = refSchema.type;
    }

    newFormData[this.getAvailableKey('newKey', newFormData)] = this.getDefaultValue(type);

    this.props.onChange(newFormData);
  };

  render() {
    const {
      uiSchema,
      formData,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      idPrefix,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
      depth,
      discriminator,
    } = this.props;

    const { rootSchema, fields, formContext } = registry;
    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, rootSchema, formData);

    // If this schema has a title defined, but the user has set a new key/label, retain their input.
    let title;
    if (this.state.wasPropertyKeyModified) {
      title = name;
    } else {
      title = schema.title === undefined ? name : schema.title;
    }

    const description = uiSchema['ui:description'] || schema.description;
    const properties = Object.keys(schema.properties || {});

    const Template = uiSchema['ui:ObjectFieldTemplate'] || registry.ObjectFieldTemplate || DefaultObjectFieldTemplate;

    const templateProps = {
      title: uiSchema['ui:title'] || title,
      description,
      TitleField,
      DescriptionField,
      depth,
      properties: properties.map(prop => {
        const addedByAdditionalProperties =
          schema.properties[prop] &&
          Object.prototype.hasOwnProperty.call(schema.properties[prop], ADDITIONAL_PROPERTY_FLAG);

        return {
          content: (
            <SchemaField
              key={prop}
              depth={depth + 1}
              disabled={disabled || discriminator?.propertyName === prop}
              formData={(formData || {})[prop]}
              idPrefix={idPrefix}
              idSchema={idSchema[prop]}
              name={prop}
              onBlur={onBlur}
              onChange={this.onPropertyChange(prop, addedByAdditionalProperties)}
              onDropPropertyClick={this.onDropPropertyClick}
              onFocus={onFocus}
              onKeyChange={this.onKeyChange(prop)}
              readonly={readonly}
              registry={registry}
              required={this.isRequired(prop)}
              schema={schema.properties[prop]}
              uiSchema={
                addedByAdditionalProperties
                  ? {
                      ...uiSchema.additionalProperties,
                      'ui:callbackDocs': uiSchema['ui:callbackDocs'],
                      'ui:responseDocs': uiSchema['ui:responseDocs'],
                      maxNest: uiSchema.maxNest,
                    }
                  : {
                      ...uiSchema[prop],
                      'ui:callbackDocs': uiSchema['ui:callbackDocs'],
                      'ui:responseDocs': uiSchema['ui:responseDocs'],
                      maxNest: uiSchema.maxNest,
                    }
              }
              wasPropertyKeyModified={this.state.wasPropertyKeyModified}
            />
          ),
          prop,
          readonly,
          disabled,
          required,
        };
      }),
      readonly,
      disabled,
      required,
      idSchema,
      uiSchema,
      schema,
      formData,
      formContext,
    };
    return <Template {...templateProps} onAddClick={this.handleAddClick} />;
  }
}

ObjectField.propTypes = types.fieldProps;
ObjectField.propTypes.discriminator = PropTypes.shape({
  propertyName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
});

export default ObjectField;
