import type { SingleSecurity } from './types';
import type { Operation } from 'oas/operation';

import { useMemo, useState } from 'react';

export function useSecurityGroups(operation: Operation, selectedAuth: string[]) {
  const securityGroups = useMemo(
    () =>
      operation.getSecurityWithTypes(true).filter(sec => {
        // Optional security requirements produce an empty array here and shouldn't be included as an available auth
        // input.
        return sec && sec.length;
      }),
    [operation],
  ) as SingleSecurity[][];

  // Use the selectedAuth from the ReferenceStore to persist the chosen security group if it exists
  const selectedAuthIndex = selectedAuth?.length
    ? securityGroups.findIndex(group => selectedAuth.includes(group[0].security._key))
    : 0;
  const [securityGroupIndex, setSecurityGroupIndex] = useState(selectedAuthIndex);
  const currentSecurityGroup = useMemo(() => {
    // If the user has a security group index that doesn't exist on this operation we should reset the security group
    // they have in place for this operation to the first one available. If we don't do this the page will crash!
    if (securityGroups[securityGroupIndex] === undefined) {
      setSecurityGroupIndex(0);
      return securityGroups[0];
    }

    return securityGroups[securityGroupIndex];
  }, [securityGroups, securityGroupIndex]) as SingleSecurity[];

  return { securityGroups, securityGroupIndex, currentSecurityGroup, setSecurityGroupIndex };
}
