import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

function EmptyStatePrompt() {
  const bem = useClassy(classes, 'EmptyStatePrompt');
  return (
    <Flex align="center" className={bem('&')} gap="xs" justify="start">
      <Icon className={bem('-icon')} isFont name="icon-help-circle" />
      <span className={bem('-label')}>
        Make a request to see them here or <a href="#try-it">Try It</a>!
      </span>
    </Flex>
  );
}

export default EmptyStatePrompt;
