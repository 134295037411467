import BaseInput from './BaseInput';
import CheckboxWidget from './CheckboxWidget';
import ColorWidget from './ColorWidget';
import EmailWidget from './EmailWidget';
import FileWidget from './FileWidget';
import HiddenWidget from './HiddenWidget';
import PasswordWidget from './PasswordWidget';
import RadioWidget from './RadioWidget';
import RangeWidget from './RangeWidget';
import SelectWidget from './SelectWidget';
import TextareaWidget from './TextareaWidget';
import TextWidget from './TextWidget';
import UpDownWidget from './UpDownWidget';
import URLWidget from './URLWidget';

export default {
  BaseInput,
  PasswordWidget,
  RadioWidget,
  UpDownWidget,
  RangeWidget,
  SelectWidget,
  TextWidget,
  EmailWidget,
  URLWidget,
  TextareaWidget,
  HiddenWidget,
  ColorWidget,
  FileWidget,
  CheckboxWidget,
};
