import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import React, { useMemo } from 'react';

import useDereference from '@core/hooks/useDereference';
import { useReferenceStore } from '@core/store';
import type { ReferenceFormSliceActions } from '@core/store/Reference/Form';

import SectionHeader from '@ui/API/SectionHeader';
import RDMD from '@ui/RDMD';

import createAccordionMultiSchemaField from './components/AccordionMultiSchemaField';
import DeprecatedAccordionWrapper from './components/DeprecatedAccordionWrapper';
import DescriptionField from './components/DescriptionField';
import Form from './components/Form';
import BaseInput from './components/Form/components/widgets/BaseInput';
import FileWidget from './components/Form/components/widgets/FileWidget';
import HiddenWidget from './components/Form/components/widgets/HiddenWidget';
import PasswordWidget from './components/Form/components/widgets/PasswordWidget';
import SelectWidget from './components/Form/components/widgets/SelectWidget';
import TextareaWidget from './components/Form/components/widgets/TextareaWidget';
import TextWidget from './components/Form/components/widgets/TextWidget';
import UpDownWidget from './components/Form/components/widgets/UpDownWidget';
import URLWidget from './components/Form/components/widgets/URLWidget';
import createSchemaField from './components/SchemaField';
import UnsupportedField from './components/UnsupportedField';
import './style.scss';

const SchemaHeader: React.FC<{ label: string; type: string }> = ({ label, type }) => (
  <SectionHeader key={`${type}-header`} data-testid="APISchema-SectionHeader" heading={label} />
);

interface APISchemaProps {
  AnyOfField: React.Component;
  OneOfField: React.Component;
  SchemaField: React.Component;
  alwaysUseDefaults: boolean;
  callbackDocs: boolean;
  /**
   * This is an object of default values that should be applied to parameters and request body properties in the API
   * schema. For projects that have JWT auth set up with us this data comes from the `parameters` property.
   */
  globalDefaults: Record<string, unknown>;
  isWebhook: boolean;
  oas: Oas;
  onChange: ReferenceFormSliceActions['updateSchemaFormData'];
  onSubmit: () => void;
  operation: Operation;
  responseDocs: boolean;
  statusCode: string;
}

const APISchema: React.FC<APISchemaProps> = ({
  alwaysUseDefaults = false,
  AnyOfField,
  callbackDocs = false,
  globalDefaults = {},
  isWebhook,
  onSubmit = () => {},
  SchemaField,
  OneOfField,
  operation,
  responseDocs = false,
  statusCode,
  ...props
}) => {
  const [isCustomSampleSelected, language, languageLibrary, formData, onChange] = useReferenceStore(store => [
    store.language.isCustomSampleSelected,
    store.language.language,
    store.language.languageLibrary,
    store.form.schemaEditor.data,
    props.onChange ?? store.form.updateSchemaFormData,
  ]);
  const { isDereferenced } = useDereference(props.oas);

  const operationId = useMemo(() => {
    /**
     * We're using the `camelCase` option here so that every `operationID` and `$id` that we handle
     * within the schema is safe from any weird characters in operationIDs (eg. `Database#resource`)
     * being improperly interpreted by AJV, causing crashes.
     *
     * We do not use this `camelCase` option anywhere else but here.
     *
     * @see {@link https://github.com/readmeio/oas/pull/631}
     * @see {@link https://github.com/readmeio/oas/pull/635}
     */
    return operation.getOperationId({ camelCase: true });
  }, [operation]);

  const jsonSchema = useMemo(() => {
    if (!isDereferenced) return null;

    if (responseDocs) {
      return operation.getResponseAsJSONSchema(statusCode);
    }

    return operation.getParametersAsJSONSchema({ globalDefaults, hideReadOnlyProperties: true });
  }, [globalDefaults, isDereferenced, operation, responseDocs, statusCode]);

  /**
   * This'll dictate if we should flatten out the rendered schema to represent how payloads are delivered within `api`:
   *
   *  - `body`: all request body parameters
   *  - `metadata`: everything else (path, query, header, cookie)
   *
   * @link http://npm.im/api
   */
  const shouldFlattenSchema = useMemo(() => {
    if (callbackDocs || responseDocs) {
      // We should never flatten out the schema for responses.
      return false;
    } else if (language === 'node' && languageLibrary?.id.join(':') === 'node:api') {
      return true;
    }

    return false;
  }, [callbackDocs, language, languageLibrary?.id, responseDocs]);

  const { bodyParams, metadataParams } = useMemo(() => {
    const metadata = (jsonSchema || []).filter(schema =>
      ['path', 'query', 'header', 'cookie'].includes(typeof schema.type === 'string' ? schema.type : schema.type[0]),
    );

    const body = (jsonSchema || []).filter(schema =>
      ['body', 'formData'].includes(typeof schema.type === 'string' ? schema.type : schema.type[0]),
    );

    return { bodyParams: body.length ? body[0] : false, metadataParams: metadata };
  }, [jsonSchema]);

  function isMultiSchema(schema) {
    return !!(schema.anyOf || schema.oneOf);
  }

  function containsMultiSchema(schemas) {
    let isMulti = false;

    schemas.forEach(schema => {
      if (isMultiSchema(schema.schema)) {
        isMulti = true;
      }
    });

    return isMulti;
  }

  function getForm(schema) {
    const widgets = {
      // 🚧 If new supported formats are added here, they must also be added to `SchemaField.getCustomType`.
      BaseInput,
      binary: FileWidget,
      blob: TextareaWidget,
      byte: TextWidget,

      // Due to the varying ways that `date` and `date-time` is utilized in API definitions for representing
      // dates the lack of wide browser support, and that it's not RFC 3339 compliant we don't support the
      // `date-time-local` input for `date-time` formats, instead treating them as general strings.
      //
      // @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#Browser_compatibility
      // @link https://tools.ietf.org/html/rfc3339
      date: TextWidget,
      dateTime: TextWidget,
      'date-time': TextWidget,

      double: UpDownWidget,
      duration: TextWidget,
      float: UpDownWidget,
      html: TextareaWidget,
      int8: UpDownWidget,
      int16: UpDownWidget,
      int32: UpDownWidget,
      int64: UpDownWidget,
      integer: UpDownWidget,
      json: TextareaWidget,
      password: PasswordWidget,
      SelectWidget,
      string: TextWidget,
      timestamp: TextWidget,
      uint8: UpDownWidget,
      uint16: UpDownWidget,
      uint32: UpDownWidget,
      uint64: UpDownWidget,
      uri: URLWidget,
      url: URLWidget,
      uuid: TextWidget,
    };

    // The following don't use input fields:
    // - response docs
    // - callback docs
    // - request docs, if the currently rendered language is a custom code sample
    if (responseDocs || callbackDocs || isCustomSampleSelected) {
      Object.keys(widgets).forEach(key => {
        widgets[key] = HiddenWidget;
      });
    }

    return (
      <Form
        key={`${schema.type}-form-${isCustomSampleSelected}`}
        callbackDocs={callbackDocs}
        fields={{
          AnyOfField,
          DescriptionField,
          SchemaField,
          OneOfField,
          UnsupportedField,
        }}
        formContext={{
          alwaysUseDefaults,
        }}
        formData={callbackDocs ? undefined : formData[schema.type]}
        id={`form-${schema.type}-${operationId}`}
        idPrefix={`${schema.type}-${operationId}`}
        onChange={form => {
          if (callbackDocs) return () => {};
          return onChange({ [schema.type]: form.formData }, form.formDataFiles || {});
        }}
        responseDocs={responseDocs}
        schema={schema.schema}
        widgets={widgets}
      />
    );
  }

  if (shouldFlattenSchema) {
    // Skip rendering empty form if there are no parameters
    if (!bodyParams && !metadataParams.length) {
      return null;
    }

    return (
      <form className="param-type rm-APISchema" id={`form-${operationId}`} name="Parameters" onSubmit={onSubmit}>
        {typeof bodyParams === 'object' && (
          <>
            <SchemaHeader label={bodyParams.label as string} type={bodyParams.type as string} />
            {!!bodyParams.description && <RDMD className="schema-description">{bodyParams.description}</RDMD>}
            <div className={`rm-ParamContainer ${isMultiSchema(bodyParams.schema) ? 'multischema' : ''}`}>
              {getForm(bodyParams)}
              {'deprecatedProps' in bodyParams && !!bodyParams.deprecatedProps?.schema && (
                <DeprecatedAccordionWrapper childType={bodyParams.type}>
                  {getForm(bodyParams.deprecatedProps)}
                </DeprecatedAccordionWrapper>
              )}
            </div>
          </>
        )}

        {!!metadataParams?.length && (
          <>
            <SchemaHeader label="Metadata" type="metadata" />
            <div className={`rm-ParamContainer ${containsMultiSchema(metadataParams) ? 'multischema' : ''}`}>
              {metadataParams.map(schema => (
                <>
                  {getForm(schema)}
                  {'deprecatedProps' in schema && !!schema.deprecatedProps?.schema && (
                    <DeprecatedAccordionWrapper childType={schema.type}>
                      {getForm(schema.deprecatedProps)}
                    </DeprecatedAccordionWrapper>
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </form>
    );
  }

  // Skip rendering empty form if there are no parameters
  if (!jsonSchema || (Array.isArray(jsonSchema) && !jsonSchema.length)) {
    return null;
  }

  return (
    <form
      className={`param-type${responseDocs ? ' response' : ''} rm-APISchema`}
      id={`form-${operationId}`}
      name="Parameters"
      onSubmit={onSubmit}
    >
      {jsonSchema.map(schema => {
        return (
          <React.Fragment key={`${schema.type}-block`}>
            <SchemaHeader
              label={isWebhook && schema.type === 'body' ? 'payload' : schema.label}
              type={schema.type ? schema.type : 'other'}
            />
            {!!schema.description && !responseDocs && <RDMD className="schema-description">{schema.description}</RDMD>}
            <div className={`rm-ParamContainer ${isMultiSchema(schema.schema) ? 'multischema' : ''}`}>
              {getForm(schema)}
              {!!schema.deprecatedProps?.schema && (
                <DeprecatedAccordionWrapper childType={schema.type}>
                  {getForm(schema.deprecatedProps)}
                </DeprecatedAccordionWrapper>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </form>
  );
};

export default APISchema;
export function createSchema(oas: Oas, operation: Operation, forResponse?: boolean, forCallbacks?: boolean) {
  // These component creation methods should remain **outside** of the function that `createParams` returns because
  // anytime data within the form is edited we don't want to recreate every form component at the same time as this
  // introduces the possibility of the user input losing focus.
  //
  // This unfortunately can't be easily tested without introducing Puppeteer testing of the explorer as
  // `document.activeElement` isn't exposed within Enzyme (and also Enzyme is deprecating the `.simulate()` method it
  // provides which will make it even more difficult to determine which element is in focus).
  //
  // https://github.com/readmeio/api-explorer/commit/2313073711f3bb7b40df6e33eaf403e62caa22a3
  // https://github.com/enzymejs/enzyme/issues/2173#issuecomment-505551552
  const SchemaField = createSchemaField();
  const AccordionMultiSchemaField = createAccordionMultiSchemaField();

  // eslint-disable-next-line react/display-name
  return props => {
    return (
      <APISchema
        {...props}
        AnyOfField={AccordionMultiSchemaField}
        callbackDocs={forCallbacks}
        OneOfField={AccordionMultiSchemaField}
        responseDocs={forResponse}
        SchemaField={SchemaField}
      />
    );
  };
}
