import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import React, { useCallback, useMemo } from 'react';

import useDereference from '@core/hooks/useDereference';

import Accordion, { AccordionPanel, AccordionToggle } from '@ui/Accordion';
import SectionHeader from '@ui/API/SectionHeader';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import RDMD from '@ui/RDMD';

import ResponseSchemaItems from './components/SchemaItems';
import classes from './style.module.scss';
import './style.scss';

interface APIResponseSchemaPickerProps {
  /**
   * Boolean to determine whether to auto-expand the first 2xx response schema.
   * This is a project-level flag.
   */
  defaultExpandResponseSchema?: boolean;

  /**
   * Boolean to add a class for callback responses
   */
  isCallback?: boolean;

  /**
   * An `Oas` class instance.
   * @link https://npm.im/oas
   */
  oas: Oas;

  /**
   * An `Operation` class instance. This can be accessed from `oas` by running `.operation('/pets', 'get')`.
   */
  operation: Operation;
}

const APIResponseSchemaPicker = ({
  defaultExpandResponseSchema,
  oas,
  operation,
  isCallback,
}: APIResponseSchemaPickerProps) => {
  const { isDereferenced } = useDereference(oas);

  const onChange = useCallback(() => {}, []);

  const statusCodes = useMemo(() => {
    if (!isDereferenced) return [];

    return operation.getResponseStatusCodes();
  }, [isDereferenced, operation]);

  const options = useMemo(() => {
    return statusCodes.map((code, idx) => {
      const response = operation.getResponseByStatusCode(code);

      if (
        typeof response === 'object' &&
        (!response.content || !Object.keys(response.content).length) &&
        typeof response === 'object' &&
        (!response.headers || !Object.keys(response.headers).length)
      ) {
        return (
          <Flex
            key={`status-${code}`}
            align="stretch"
            className={`${classes['APIResponseSchemaPicker-option']} ${
              isCallback ? classes['APIResponseSchemaPicker-option-callback'] : ''
            }`}
          >
            <div className={classes['APIResponseSchemaPicker-option-toggle']}>
              <div className={classes['APIResponseSchemaPicker-label']}>
                <HTTPStatus className={classes['APIResponseSchemaPicker-label-text']} iconOnly status={code} /> {code}
              </div>
              {!!response.description && (
                <RDMD className={classes['APIResponseSchemaPicker-description']}>{response.description}</RDMD>
              )}
            </div>
          </Flex>
        );
      }

      return (
        <AccordionPanel
          key={`status-${code}`}
          className={`${classes['APIResponseSchemaPicker-option']} rm-APIResponseSchemaPicker-option`}
          initiallyOpen={!!defaultExpandResponseSchema && idx === 0 && code.startsWith('2')}
        >
          <AccordionToggle className={classes['APIResponseSchemaPicker-option-toggle']}>
            <div>
              <div className={classes['APIResponseSchemaPicker-label']}>
                <HTTPStatus className={classes['APIResponseSchemaPicker-label-text']} iconOnly status={code} /> {code}
              </div>
              {!!(typeof response === 'object' && response.description) && (
                <RDMD className={classes['APIResponseSchemaPicker-description']}>{response.description}</RDMD>
              )}
            </div>
          </AccordionToggle>
          <ResponseSchemaItems
            className={classes['APIResponseSchemaPicker-items']}
            oas={oas}
            onChange={onChange}
            operation={operation}
            status={code}
          />
        </AccordionPanel>
      );
    });
  }, [statusCodes, operation, defaultExpandResponseSchema, oas, onChange, isCallback]);

  if (!options.length) {
    return null;
  }

  return (
    <>
      {!!isDereferenced && <SectionHeader heading={`Response${statusCodes.length > 1 ? 's' : ''}`} />}
      <Accordion className={`${classes.APIResponseSchemaPicker} rm-APIResponseSchemaPicker`} collapsible={false}>
        {options}
      </Accordion>
    </>
  );
};

export default React.memo(APIResponseSchemaPicker);
