import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getDefaultFormState,
  retrieveSchema,
  shouldRender,
  toIdSchema,
  getDefaultRegistry,
  deepEquals,
  isObject,
  isPolymorphic,
  hasSchemaType,
  shouldAlwaysUseDefaults,
} from '../utils';

export default class Form extends Component {
  static defaultProps = {
    disabled: false,
    uiSchema: {},
  };

  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props, props.formData);
    if (this.props.onChange && !deepEquals(this.state.formData, this.props.formData)) {
      this.props.onChange(this.state);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextState = this.getStateFromProps(nextProps, nextProps.formData);
    if (
      !deepEquals(nextState.formData, nextProps.formData) &&
      !deepEquals(nextState.formData, this.state.formData) &&
      this.props.onChange
    ) {
      this.props.onChange(nextState);
    }
    this.setState(nextState);
  }

  getStateFromProps(props, inputFormData) {
    const schema = 'schema' in props ? props.schema : this.props.schema;
    const uiSchema = 'uiSchema' in props ? props.uiSchema : this.props.uiSchema;

    const alwaysUseDefaults = shouldAlwaysUseDefaults(
      'formContext' in props ? props.formContext : 'formContext' in this.props ? this.props.formContext : {},
    );

    const edit = typeof inputFormData !== 'undefined';
    const rootSchema = schema;
    const formData = getDefaultFormState(schema, {
      alwaysUseDefaults,
      formData: inputFormData,
      rootSchema,
    });

    const retrievedSchema = retrieveSchema(schema, rootSchema, formData);
    const idSchema = toIdSchema(retrievedSchema, uiSchema['ui:rootFieldId'], rootSchema, formData, props.idPrefix);
    return {
      schema,
      uiSchema,
      idSchema,
      formData,
      edit,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onChange = (formData, formDataFiles) => {
    if (isObject(formData) || Array.isArray(formData)) {
      const newState = this.getStateFromProps(this.props, formData);
      // eslint-disable-next-line no-param-reassign
      formData = newState.formData;
    }

    const state = { formData, formDataFiles: formDataFiles || {} };

    this.setState(state, () => this.props.onChange && this.props.onChange(state));
  };

  onBlur = (...args) => {
    if (this.props.onBlur) {
      this.props.onBlur(...args);
    }
  };

  onFocus = (...args) => {
    if (this.props.onFocus) {
      this.props.onFocus(...args);
    }
  };

  getRegistry() {
    // For BC, accept passed SchemaField and TitleField props and pass them to
    // the "fields" registry one.
    const { fields, widgets } = getDefaultRegistry();
    return {
      fields: { ...fields, ...this.props.fields },
      widgets: { ...widgets, ...this.props.widgets },
      ArrayFieldTemplate: this.props.ArrayFieldTemplate,
      ObjectFieldTemplate: this.props.ObjectFieldTemplate,
      FieldTemplate: this.props.FieldTemplate,
      definitions: this.props.schema.definitions || {},
      rootSchema: this.props.schema,
      formContext: this.props.formContext || {},
    };
  }

  render() {
    const { id, idPrefix, className, disabled, formContext, responseDocs, callbackDocs } = this.props;
    const { schema, uiSchema, formData, idSchema } = this.state;
    const registry = this.getRegistry();
    const _SchemaField = registry.fields.SchemaField;

    // Schemas that are Polymorphic or Objects have a different intiial template than everything else
    //  We can start Polymorphic and Objects at the expected 0 starter depth, but everything else needs to
    //  be one higher.
    let initialDepth = isPolymorphic(schema) || hasSchemaType(schema, 'object') ? 0 : 1;

    // Response docs currently count as an additional depth layer, alternating the colors once from the base background.
    // This increment ensures that the elements take that into account
    if (responseDocs || callbackDocs) {
      initialDepth += 1;
    }
    return (
      <div className={className || 'rjsf'} id={id}>
        <_SchemaField
          depth={initialDepth}
          disabled={disabled}
          formContext={formContext}
          formData={formData}
          idPrefix={idPrefix}
          idSchema={idSchema}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          registry={registry}
          schema={schema}
          uiSchema={{
            ...uiSchema,
            'ui:callbackDocs': callbackDocs,
            'ui:responseDocs': responseDocs,
            maxNest: initialDepth + 3,
          }}
        />
      </div>
    );
  }
}

Form.propTypes = {
  ArrayFieldTemplate: PropTypes.elementType,
  className: PropTypes.string,
  fields: PropTypes.objectOf(PropTypes.elementType),
  FieldTemplate: PropTypes.elementType,
  formContext: PropTypes.object,
  formData: PropTypes.any,
  id: PropTypes.string,
  ObjectFieldTemplate: PropTypes.elementType,
  onChange: PropTypes.func,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object,
  widgets: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
};
