import React, { useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import type { VariablesContextValue } from '@core/context';
import { ProjectContext, VariablesContext } from '@core/context';
import { useReferenceStore } from '@core/store';
import type { ReferenceAuthSliceState } from '@core/store/Reference/Auth';

import { MockHarContext } from '@routes/Reference/context/HARContext';

import APIAuth, { APILegacyAuth } from '@ui/API/Auth';
import type { AuthContainerProps, SingleSecurity } from '@ui/API/Auth/types';

function AuthContainer({ setSelectedHar = () => {}, apiDefinition, hasLegacyUI = true, ...props }: AuthContainerProps) {
  const { pathname } = useLocation();
  const { setShouldUseMockHars } = useContext(MockHarContext);
  const { project } = useContext(ProjectContext);
  const { user } = useContext(VariablesContext) as VariablesContextValue;

  const [auth, selectedAuth, group, groups, isGroupLoggedIn, updateAuth, updateGroup, updateSelectedAuth] =
    useReferenceStore(s => [
      s.auth.auth,
      s.auth.selectedAuth,
      s.auth.group,
      s.auth.groups,
      s.auth.isGroupLoggedIn,
      s.auth.updateAuth,
      s.auth.updateGroup,
      s.auth.updateSelectedAuth,
    ]);

  const { name } = project;

  // todo: should pull this apiKeyPath from dash config
  const apiKeyPath = `/login?redirect_uri=${pathname}`;

  const handleUpdateAuth = useCallback<typeof updateAuth>(
    (...args) => {
      setSelectedHar(null);
      updateAuth(...args);
    },
    [setSelectedHar, updateAuth],
  );

  const onAuthGroupChange = useCallback(
    (groupId: ReferenceAuthSliceState['group']) => {
      updateGroup({ apiDefinition, groupId, user });
    },
    [apiDefinition, updateGroup, user],
  );

  const updateSecurityGroup = useCallback(
    (securityGroup: SingleSecurity[], hasAuth: boolean) => {
      // If there is no auth on the operation we instead fallback to mimic metrics working on the client-side
      setShouldUseMockHars(!hasAuth);

      // Keep track of what security/auth groups the user has currently selected so we can not only persist their
      // selection but also prevent us from passing duplicate tokens for the same security scheme down to HAR creation.
      // RM-2801, RM-2435
      const newSelectedAuth = new Set<string>();
      securityGroup?.forEach(secg => {
        newSelectedAuth.add(secg?.security?._key);
      });
      updateSelectedAuth([...newSelectedAuth].filter(Boolean));
    },
    [setShouldUseMockHars, updateSelectedAuth],
  );

  return hasLegacyUI ? (
    <APILegacyAuth
      auth={auth}
      group={group as string}
      groups={groups}
      isGroupLoggedIn={isGroupLoggedIn}
      onAuthGroupChange={onAuthGroupChange}
      pathname={pathname}
      selectedAuth={selectedAuth}
      updateAuth={handleUpdateAuth}
      updateSecurityGroup={updateSecurityGroup}
      {...props}
    />
  ) : (
    <APIAuth
      apiKeyPath={apiKeyPath}
      auth={auth}
      group={group as string}
      groups={groups}
      isGroupLoggedIn={isGroupLoggedIn}
      onAuthGroupChange={onAuthGroupChange}
      pathname={pathname}
      projectName={name}
      selectedAuth={selectedAuth}
      updateAuth={handleUpdateAuth}
      updateSecurityGroup={updateSecurityGroup}
      {...props}
    />
  );
}

export default AuthContainer;
