import type { ColumnDef } from '@tanstack/react-table';

import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { APIKeyRequestRow } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import {
  APIKeyCell,
  ElapsedTimeCell,
  MethodCell,
  StatusCell,
  TextCell,
  UserAgentCell,
} from '@ui/ReactTable/components/Cells';

export const DEFAULT_SORT_DIRECTION = 'desc';

interface ITablePrefs {
  columns: ColumnDef<APIKeyRequestRow>[];
  direction: 'asc' | 'desc';
  endpoint: string;
  order: string[];
  prefsName: TablePrefsKey;
  sort: string;
  visibility: Record<string, boolean>;
}

// Columns are ultimately plain objects, but createColumnHelper helps manage expected types
const columnHelper = createColumnHelper<APIKeyRequestRow>();

// Default column options
export const defaultColumn: Partial<ColumnDef<APIKeyRequestRow>> = {
  enableSorting: false,
  cell: ({ getValue }) => <TextCell value={getValue<string>() || ''} />,
};

// The cell render functions below use the useClassy hook, but rules-of-hooks doesn't recognize it as React function component
export const Columns = [
  columnHelper.accessor(Column.CreatedAt, {
    header: columnHeaders[Column.CreatedAt],
    enableSorting: true,
    size: 120,
    cell: ({ getValue }) => <ElapsedTimeCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.Status, {
    header: columnHeaders[Column.Status],
    maxSize: 70,
    cell: ({ getValue }) => <StatusCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.Method, {
    header: columnHeaders[Column.Method],
    maxSize: 70,
    cell: ({ getValue }) => <MethodCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.Path, {
    header: columnHeaders[Column.Path],
    enableHiding: false,
    minSize: 200,
    cell: ({ getValue }) => <TextCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.GroupEmail, {
    header: columnHeaders[Column.GroupEmail],
    minSize: 200,
    enableHiding: false,
    cell: ({ getValue }) => <TextCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.GroupId, {
    header: columnHeaders[Column.GroupId],
    minSize: 200,
    cell: ({ getValue }) => <APIKeyCell value={getValue<string>()} />,
  }),
  columnHelper.accessor(Column.UserAgent, {
    header: columnHeaders[Column.UserAgent],
    cell: ({ getValue }) => <UserAgentCell value={getValue()} />,
  }),
];

/**
 * This returns all of the table preferences mapped by TableType
 * - columns: the columns array
 * - direction: the default sorting direction
 * - endpoint: endpoint to fetch data for table from
 * - order: the default order of columns
 * - sort: the default column to sort by
 * - prefsName: the key used when storing order and visibility prefs in localStorage under '@readme:tablePrefs' key
 * - visibility: the default visibilty of columns
 */
export const tablePrefs = {
  columns: Columns,
  direction: DEFAULT_SORT_DIRECTION,
  endpoint: 'requests/list',
  // @ts-ignore - accessorKey exists but react-table types is missing it
  order: Columns.map(({ accessorKey }) => accessorKey),
  sort: 'lastRequest',
  prefsName: TablePrefsKey.RealtimeMyRequests,
  visibility: {
    [Column.GroupId]: false,
    [Column.UserAgent]: false,
  },
} as ITablePrefs;
