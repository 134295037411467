import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { dataURItoBlob, shouldRender } from '../../utils';

import classes from './style.module.scss';

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

function processFile(file) {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = event => {
      resolve({
        input: file,
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
  const { filesInfo } = props;
  if (filesInfo.length === 0) {
    return null;
  }
  return (
    <ul className={classes['Param-fileInfo']}>
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type } = fileInfo;
        return (
          <li key={key} className={classes['Param-fileInfo-description']}>
            <div className={classes['Param-fileInfo-name']}>{name}</div>
            <small>{type}</small>
            <small>{size} bytes</small>
          </li>
        );
      })}
    </ul>
  );
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter(dataURL => typeof dataURL === 'string')
    .filter(dataURL => dataURL.toLowerCase() !== 'null')
    .map(dataURL => {
      try {
        const { blob, name } = dataURItoBlob(dataURL);
        return {
          name,
          size: blob.size,
          type: blob.type,
        };
      } catch (err) {
        return false;
      }
    })
    .filter(Boolean);
}

class FileWidget extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const values = Array.isArray(value) ? value : [value];

    this.state = {
      values,
      filesInfo: extractFileInfo(values),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onChange = event => {
    const { multiple, onChange } = this.props;
    processFiles(event.target.files).then(filesInfo => {
      const state = {
        values: filesInfo.map(fileInfo => fileInfo.dataURL),
        fileInputs: filesInfo
          .map(fileInfo => ({ [fileInfo.name]: fileInfo.input }))
          .reduce((prev, next) => Object.assign(prev, next)),
        filesInfo,
      };

      this.setState(state, () => {
        if (multiple) {
          onChange(state.values, state.fileInputs);
        } else {
          onChange(state.values[0], state.fileInputs);
        }
      });
    });
  };

  render() {
    const { multiple, id, readonly, disabled, autofocus, options } = this.props;
    const { filesInfo } = this.state;
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={classes['Param-fileInput']}>
          <span
            className={`Input Input_sm ${classes['Param-fileInput-button']} icon-upload`}
            role="button"
            tabIndex="0"
          />
          <input
            ref={ref => {
              this.inputRef = ref;
            }}
            accept={options.accept}
            autoFocus={autofocus}
            className={classes['Param-fileInput-input']}
            defaultValue=""
            disabled={readonly || disabled}
            id={id}
            multiple={multiple}
            onChange={this.onChange}
            type="file"
          />
        </label>
        <FilesInfo filesInfo={filesInfo} />
      </div>
    );
  }
}

FileWidget.defaultProps = {
  autofocus: false,
};

FileWidget.propTypes = {
  autofocus: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default FileWidget;
