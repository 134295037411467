import type { $TSFixMe, SidebarCategory } from '@readme/iso';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import { HTTP_METHOD } from '@readme/iso';
import React, { useMemo } from 'react';

import Method from '@ui/API/Method';
import Flyout from '@ui/Flyout';
import QuickNav from '@ui/QuickNav';
import transformSidebarDataForQuickNav from '@ui/QuickNav/inputDataTransformers/transformSidebarDataForQuickNav';
import RDMD from '@ui/RDMD';
import Sidebar from '@ui/Sidebar';

import classes from './style.module.scss';
import './style.scss';
import TagTooltip from './TagTooltip';

interface APIHeaderProps {
  className?: string;
  doc: {
    deprecated?: boolean;
    excerpt?: string;
    slug: string;
    title: string;
    type?: string;
  };
  isWebhook?: boolean;
  oas?: Oas;
  operation?: Operation;
  servers?: {
    selected?: number;
    variables?: $TSFixMe; // @todo export the `Variables` type in `oas`
  };
  sidebar?: SidebarCategory[];
}

interface FlyoutButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  singleCol: boolean;
}

/**
 * @param {string} host
 * @param {Operation} operation
 */
function createHeaderUrl(host: string, operation: Operation, isWebhook: boolean) {
  const operationId = operation.getOperationId();

  // Split up the string based on the curly brace
  let pathParts: React.ReactNode[] | string[] = operation.path.split(/\{|\}/g);

  if (pathParts.length > 1) {
    const finalPathParts: React.ReactNode[] = [];

    pathParts.forEach((param, index) => {
      // When we split off the curly brace the even elements are the param names
      if (index % 2) {
        finalPathParts.push(
          <label
            key={`path-${operationId}_${param}-${index}`}
            className={classes['APIHeader-url-parameter']}
            htmlFor={`path-${operationId}_${param}`}
          >
            {`{${param}}`}
          </label>,
        );
      } else {
        // And the odd elements are the string literals
        finalPathParts.push(param);
      }
    });

    pathParts = finalPathParts;
  }

  return (
    <span
      className={classes['headline-container-article-info-url']}
      data-testid="serverurl"
      title={`${host}${operation.path}`}
    >
      {!isWebhook && host}
      {pathParts}
    </span>
  );
}

const FlyoutButton = ({ onClick, singleCol }: FlyoutButtonProps) => (
  <button
    aria-label="Toggle reference sidebar"
    className={`icon-menu ${classes['reference-sidebar-mobile-button']} ${
      singleCol ? classes['reference-sidebar-mobile-button_singleCol'] : ''
    }`}
    onClick={onClick}
    type="button"
  />
);

const APIHeader = ({ doc, isWebhook, oas, servers, operation, sidebar }: APIHeaderProps) => {
  const preparedQuickNavData = useMemo(() => {
    try {
      return transformSidebarDataForQuickNav(sidebar);
    } catch {
      return null;
    }
  }, [sidebar]);

  const sidebarHeader = useMemo(
    () => (
      <div className={classes['ref-QuickNav-mobile-container']}>
        <QuickNav destinations={preparedQuickNavData} modalTarget={'#QuickNav-mobile-modal-root'} />
      </div>
    ),
    [preparedQuickNavData],
  );

  const opts = useMemo(() => ({ copyButtons: true }), []);

  // filter operation tags so we only use tags with at least 2 pieces of metadata
  const tags =
    operation?.getTags().filter(tag => {
      return tag.name && (tag.description || tag.externalDocs);
    }) || [];

  return (
    <>
      <header
        className={`${classes['headline-container']} ${
          doc.type === 'basic' ? classes['headline-container_basic'] : ''
        }`}
        data-raycast-oas={doc.type === 'endpoint' && (oas?.api?._id as string)}
      >
        <div className={`${classes['headline-container-grid-item']} ${classes['button-container']}`}>
          {!!sidebar && (
            <Flyout className={'reference-flyout'} toggleButton={<FlyoutButton singleCol={doc.type === 'basic'} />}>
              <Sidebar
                categories={sidebar}
                header={preparedQuickNavData ? sidebarHeader : null}
                id="reference-sidebar"
                navClass="mobile"
                pathRoot="reference"
              />
            </Flyout>
          )}
          <header id="content-head">
            <h1>
              {doc.title}
              {tags?.length > 0 && <TagTooltip pageId={doc.slug} tags={tags} />}
            </h1>
          </header>
        </div>
        {(doc.type === 'endpoint' || doc.type === 'webhook') && !!oas && !!operation && (
          <div className={classes['headline-container-article-info']}>
            <Method
              className={classes['headline-container-api-method']}
              fixedWidth={doc.type === 'endpoint' || doc.type === 'webhook'}
              type={doc.type === 'webhook' ? HTTP_METHOD.WEBHOOK : (operation.method as HTTP_METHOD)}
            />{' '}
            {!!(operation?.isDeprecated() || doc.deprecated) && (
              <Method className={classes['headline-container-api-method']} type={HTTP_METHOD.DEPRECATED} />
            )}
            {createHeaderUrl(oas.url(servers?.selected, {}), operation, !!isWebhook)}
          </div>
        )}
        {!!doc.excerpt && (
          <RDMD
            body={doc.excerpt}
            className={`${classes['grid-item']} ${classes.excerpt}`}
            opts={opts}
            // @ts-ignore `role` doesn't exist within `React.ElementType`. Ignoring until we find a better type in RDMD.
            role="doc-subtitle"
          />
        )}
      </header>
    </>
  );
};

export default React.memo(APIHeader);
