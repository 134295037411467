import pluralize from 'pluralize';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useRealtimeStore } from '@core/store/Realtime';
import type { KeyInsightsResponse, APIKey } from '@core/types/metrics';
import prettyNumber from '@core/utils/prettyNumber';

import Flex from '@ui/Flex';
import APIKeyDropdown from '@ui/Metrics/APIKeyDropdown';
import UserAgentPlatformGraphic from '@ui/Metrics/UserAgentPlatformGraphic';

import MyRequestsDateRange from './DateRange';
import classes from './style.module.scss';

interface Props {
  groupId: string;
  groups: APIKey[];
  isDevDashEnabled: boolean;
  isLoggedIn: boolean;
  onKeyChange: (key: string) => void;
}

const MyRequestsHeader = ({ isDevDashEnabled, groupId, groups, isLoggedIn, onKeyChange }: Props) => {
  const bem = useClassy(classes, 'MyRequestsHeader');

  // Get filters from Realtime global store
  const [isReady, getFiltersQueryString] = useRealtimeStore(state => [state.isReady, state.getFiltersQueryString]);

  // Omit certain keys from query params
  const queryParamsString = getFiltersQueryString({
    pickKeys: ['groupId', 'rangeEnd', 'rangeLength', 'rangeStart', 'resolution'],
  });

  const { data, isLoading } = useMetricsAPI<KeyInsightsResponse>(
    `developers/keyInsights?${queryParamsString}`,
    isReady,
  );

  const { totalRequests = 0, userAgents } = data || {};

  const requests = useMemo(() => {
    return `${prettyNumber(totalRequests)} ${pluralize('Request', totalRequests)}`;
  }, [totalRequests]);

  const apiKeys = useMemo(() => {
    return groups || [];
  }, [groups]);

  return (
    <Flex align="center" className={bem('&')} gap="xs" justify="start" tag="header">
      <APIKeyDropdown
        activeAPIKey={groupId}
        apiKeys={apiKeys}
        disabled={!isLoggedIn}
        menuHeader="API Keys"
        onClick={onKeyChange}
        showOutline
      />

      {!isLoading && !!data && (
        <Flex align="center" gap="sm">
          <Flex className={bem('-insights-requests')} gap="xs">
            {requests}
          </Flex>
          <Flex gap="sm">
            {userAgents?.map((ua, i: number) => (
              <Flex key={i} align="center" gap="xs">
                <UserAgentPlatformGraphic platform={ua.platform} />
                <span>{ua.percent}%</span>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}

      {!!isLoggedIn && <MyRequestsDateRange isDevDashEnabled={isDevDashEnabled} />}
    </Flex>
  );
};

export default MyRequestsHeader;
